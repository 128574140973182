// TODO: This will need adjusting due to the nesting
// A lot of places will have to be tx.drug.<field>

import React, { useEffect, useState } from 'react'; 
import { Form, Button, Row, Col, Modal, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import ObjectSelector from '../ObjectSelector';
import utils from "../../../utils";

import '../../../styles/layout/_layout.scss';

function TxEditor(props) {

  /*--------------------------State variables---------------------------*/
  const [tx, setTx] = useState(null);
  const [ drugOptions, setDrugOptions ] = useState();

  async function fetchData(){

    let txResult = await utils.getData('get_therapeutic', props.apiKey, "?product_id="+props.txId)
    if (props.txId === null){
      setTx({
        product_id:null,
        brand_name:null,
        alt_brand_names:null,   
        is_originator:false,
        manufacturer_id:null,
        drug:null,
        manufacturer:null,
      })
    } 
    
    if (txResult !== null){
      setTx(txResult)
    }
  }

  // gets updated drugs if new is created or one is edited
  const getDrugOptions = async () => {
    const result = await utils.getData('get_drugs', props.apiKey)
    setDrugOptions(result)
  }

  useEffect(()=>{
    fetchData();
    getDrugOptions();
  // eslint-disable-next-line
  },[props.apiKey, props.txId])
  

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit (e){
    e.preventDefault();
    e.stopPropagation();

    const data = tx
    const result = await utils.upsert('upsert_therapeutic', props.apiKey, data)
    if (result !== null) {
      props.closePopup()
    }
  }

  function printState(){
    console.log(tx)
  }
  
  
  function setManufacturer(mfg) {
    if (mfg ===null){
      setTx({
        ...tx,
        manufacturer_id: null,
        manufacturer: null
      })
    } else {
      setTx({
        ...tx,
        manufacturer_id: mfg.org_id,
        manufacturer: mfg
      })
    }
  }

  function setDrug(drug) {
    setTx({
      ...tx,
      drug_id: drug.drug_id,
      drug: drug
    })
  }

  return (
    <div>
    {tx===null?null:
      <Modal size='xl' show={true} className={'alc-app'}>
        <Modal.Header>
          <Modal.Title>Drug Product Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
              <Col>
                <div className='input-text'>Brand Name</div>
                <input
                  className='form-ctrl'
                  type='text'
                  value={tx.brand_name}
                  onChange={(e) => setTx({ ...tx, brand_name: e.target.value })}
                ></input>
                <div className='input-text'>Manufacturer</div>
                  <ObjectSelector
                    apiKey={props.apiKey}
                    className='select-lg'
                    type={'organization'}
                    obj={tx.manufacturer}
                    onChange={setManufacturer}
                  />
                <div className='input-text'>Include for Visuals?</div>
                <Form.Check
                  checked={tx.include_in_vizs}
                  onChange={() => setTx({ ...tx, 
                      include_in_vizs:!tx.include_in_vizs,
                      exclude_reason:null
                    })}
                />
                {!tx.include_in_vizs?
                  <>
                    <div className='input-text'>Exclude Reason</div>
                    <input
                      className='form-ctrl'
                      type='text'
                      value={tx.exclude_reason}
                      onChange={(e) => setTx({ ...tx, exclude_reason:e.target.value })}
                      ></input>
                  </>: null
                }
              </Col>
              <Col>
                <div className='input-text'>Alt Brand Name</div>
                <textarea
                  className='textarea'
                  value={tx.alt_brand_names}
                  onChange={(e) => setTx({ ...tx, alt_brand_names:e.target.value })}
                ></textarea>
                {/* drug ObjectSelector */}
                <div className='input-text'>Drug Name</div>
                <ObjectSelector
                  apiKey={props.apiKey}
                  className='select-lg'
                  data={drugOptions}
                  props={props.closePopup}
                  appClass={props.appClass}
                  type={'drug'}
                  obj={tx.drug}
                  onChange={setDrug} 
                  onSubmit={getDrugOptions}
                />
                <div className='input-text'>Is Originator</div>
                <Form.Check
                  checked={tx.is_originator}
                  onChange={(e) => {
                    setTx({...tx, is_originator:!tx.is_originator})
                  }}
                />
                  <div className='input-text'>Source</div>
                  <input
                    className='form-ctrl'
                    type='text'
                    value={tx.source}
                    onChange={(e) => setTx({ ...tx, source:e.target.value })}
                  ></input>
              </Col>
            </Row>
            <ButtonToolbar className='editor-btn'>
              <ButtonGroup>
                <Button variant="secondary" onClick={props.closePopup} className='btn-primary m-i-end-03'>Close</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button type="submit" variant="primary" onClick={handleSubmit} className='btn-primary m-i-end-03'>Submit</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button variant="primary" onClick= {printState} className='btn-primary'>Check TX</Button>
              </ButtonGroup>
            </ButtonToolbar>
        </Modal.Body>
      </Modal>
    }
    </div>
  )
}

export default TxEditor;
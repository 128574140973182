// TODO: (Medium Priority) add validations to ensure that there are no reapeated combintions
// of organization id and enum.

import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, Table, Col, Row, ButtonGroup, ButtonToolbar } from 'react-bootstrap'

import ObjectSelector from '../ObjectSelector';
import SimpleSelect from '../SimpleSelect';
import EnumSelector from '../EnumSelector';
import CONSTANTS from "../../../constants"
import utils from "../../../utils"
import '../../../styles/layout/_layout.scss'
import ProductionLocationEditor from './ProductionLocationEditor'

function OrganizationEditor({ id, apiKey, onSubmit, closePopup, appClass }) {
  const [organization, setOrganization] = useState(null)
  const [countries, setCountries] = useState([])
  const [errors, setErrors] = useState([])
  const partners = ['WHO', 'GAVI', 'BMGF', 'CHAI', 'UNICEF', '']

  useEffect(() => {
    async function getOrganization() {
      if (id === null) {
        setOrganization(CONSTANTS.SHELL_ORGANIZATION)
      } else {
        const result = await utils.getData("get_organization", apiKey, "?organization_id=" + id)
        if (result !== null) {
          setOrganization(result)
        } else {
          alert("Data failed to load")
          setOrganization(CONSTANTS.SHELL_ORGANIZATION)
        }
      }
      const countryResult = await utils.getData("get_countries", apiKey)
      setCountries(countryResult)
    }
    getOrganization()
    return () => { }
  }, [id, apiKey])

  /*-----------------Form Submission-----------------------*/
  async function handleSubmit(e) {
    const data = organization
    e.preventDefault();
    e.stopPropagation();

    let foundOrganization = new Set()
    let foundNames = new Set()
    let submissionErrors = []

    for (let i = 0; i < organization.alt_names.length; i++) {

      if (foundOrganization.has(organization.alt_names[i].partner)) {
        submissionErrors.push(organization.alt_names[i].partner + " has two or more alternatives")
      }

      if (foundNames.has(organization.alt_names[i].alt_org_name)) {
        submissionErrors.push(organization.alt_names[i].alt_org_name + " appears twice")
      }

      if (organization.alt_names[i].partner !== '') {
        foundOrganization.add(organization.alt_names[i].partner)
      } else {
        foundNames.add(organization.alt_names[i].alt_org_name)
      }
    }

    for (let i = 0; i < organization.production_locations.length; i++) {
      if (organization.production_locations[i].ISO !== '') {
        foundOrganization.add(organization.production_locations[i].ISO)
      }
    }

    // Get the entire ancestry of the organization--itself, parent, parent's parent...
    var ancestry = new Set()
    var o = organization
    while (o !== null) {
      if (o.parent_id !== null) {
        ancestry.add(o.parent_id)
      }
      o = o.parent
    }
    if (ancestry.has(organization.org_id)) {
      submissionErrors.push("The organization can't be its own parent, grandparent...")
    }

    setErrors(submissionErrors)
    if (submissionErrors.length > 0) {
      return
    }

    var result
    result = await utils.upsert("upsert_organization", apiKey, data)
    if (result !== null) {
      onSubmit(result)
    }
  }

  function setPqExperience() {
    setOrganization({
      ...organization,
      pq_experience: !organization.pq_experience
    })
  }

  function setLicensureExperience() {
    setOrganization({
      ...organization,
      licensure_experience: !organization.licensure_experience
    })
  }

  function addSynonym() {
    const tempAltNames = organization.alt_names
    tempAltNames.push({ alt_org_name: '', partner: '' })
    setOrganization({ ...organization, alt_names: tempAltNames })
  }

  function setSynonym(synonym, index) {
    const synonyms = [...organization.alt_names]
    synonyms[index].alt_org_name = synonym
    setOrganization({ ...organization, alt_names: synonyms })
  }

  function setPartner(partner, index) {
    const synonyms = [...organization.alt_names]
    synonyms[index].partner = partner
    setOrganization({ ...organization, alt_names: synonyms })
  }

  function deleteSynonym(index) {
    const tempAltNames = [...organization.alt_names]
    tempAltNames.splice(index, 1)
    setOrganization({ ...organization, alt_names: tempAltNames })
  }

  function checkOrganization() {
    console.log("Organization", organization)
  }

  function setProductionLocation(location_name, country_id, city_id, index) {
    const tempProdLocs = [...organization.production_locations]
    tempProdLocs[index].location_name = location_name
    tempProdLocs[index].country_id = country_id
    tempProdLocs[index].city_id = city_id
    setOrganization({ ...organization, production_locations: tempProdLocs })
  }

  function addProductionLocation() {
    const tempProdLocs = [...organization.production_locations]
    tempProdLocs.push({ org_id: organization.org_id, location_name: '', country: {}, city: {} })
    setOrganization({ ...organization, production_locations: tempProdLocs })
  }

  function deleteProductionLocation(index) {
    const tempProdLocs = [...organization.production_locations]
    tempProdLocs.splice(index, 1)
    setOrganization({ ...organization, production_locations: tempProdLocs })
  }

  function setParentOrganization(org) {
    if (org === null) {
      setOrganization({
        ...organization,
        parent_id: null,
        parent: null
      })
    } else {
      setOrganization({
        ...organization,
        parent_id: org.org_id,
        parent: org
      })
    }
  }

  function setDeleted() {
    if (organization.deleted) {
      if (!window.confirm("This will bring back " + organization.org_name + " are you sure you want to proceed?")) {
        return
      }
    } else {
      if (!window.confirm("This will delete " + organization.org_name + " are you sure you want to proceed?")) {
        return
      }
    }
    setOrganization({ ...organization, deleted: !organization.deleted })
  }

  return (
    <Modal show={true} size="lg" className={'alc-app'}>
      <Modal.Header>
        <Modal.Title>Organization Editor</Modal.Title>
      </Modal.Header>
      {
        organization !== null ?
          <Modal.Body>
            <Row>
              <Col className='organization-details'>
                <Row>
                  <Row>
                    <div className='input-text'>Organization Name</div>
                    <input
                      className='form-ctrl'
                      type="text"
                      value={organization !== null ? organization.org_name : ''}
                      onChange={(e) => setOrganization({ ...organization, org_name: e.target.value })} />
                  </Row>
                  <Row>
                    <Col>
                      <div className='input-text'>Organization Type</div>
                      <input
                        className='form-ctrl'
                        type="text"
                        value={organization.org_type}
                        onChange={(e) => setOrganization({ ...organization, org_type: e.target.value })} />
                    </Col>
                    <Col>
                      <div className='input-text'>Organization Country</div>
                      <ObjectSelector
                        apiKey={apiKey}
                        obj={organization.country}
                        data={countries}
                        onChange={(country) => {
                          setOrganization({
                            ...organization,
                            country: country,
                            hq_country: country.ISO
                          })
                        }}
                        type='country'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='input-text'>Annual Revenue</div>
                      <EnumSelector
                        className="select"
                        enum_type="org_annual_revenue"
                        apiKey={apiKey}
                        value={organization.annual_revenue}
                        onChange={(e) => setOrganization({ ...organization, annual_revenue: e.label })} />
                    </Col>
                    <Col>
                      <div className='input-text'>Parent Organization</div>
                      <ObjectSelector
                        apiKey={apiKey}
                        obj={organization.parent}
                        onChange={setParentOrganization}
                        isClearable={true}
                        type='organization'
                      />
                    </Col>
                  </Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Check type="checkbox"
                          label='PQ Experience'
                          className='input-text'
                          onChange={setPqExperience}
                          checked={organization.pq_experience} />
                      </Col>
                      <Col>
                        <Form.Check type="checkbox"
                          className='input-text'
                          label="Licensure Experience"
                          onChange={setLicensureExperience}
                          checked={organization.licensure_experience} />
                      </Col>
                      <Col>
                        <Form.Check
                          label='Gavi Visible'
                          className='input-text'
                          checked={organization.gavi_visible}
                          onChange={() => setOrganization({ ...organization, gavi_visible: !organization.gavi_visible })} />
                      </Col>
                      <Col>
                        <strong>
                          <Form.Check
                            label='Delete'
                            className='input-text'
                            checked={organization.deleted}
                            onChange={setDeleted} />
                        </strong>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <strong className=''>Production Locations</strong>
                      </Col>
                      <Col>
                        <div className='clickable-text m-i-end-06' onClick={addProductionLocation} size="sm">add</div>
                      </Col>
                    </Row>
                    <Table>
                      <tbody className='justify-center'>
                        {organization.production_locations.map((pl, i) => {
                          return <ProductionLocationEditor
                            apiKey={apiKey}
                            countries={countries}
                            production_location={pl}
                            setProductionLocation={setProductionLocation}
                            deleteProductionLocation={deleteProductionLocation}
                            index={i}
                            appClass={appClass}
                          />
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                <div><strong>Synonyms</strong></div>
                <Table>
                  <thead className='table-heading'>
                    <tr>
                      <th>Synonym</th>
                      <th>Partners</th>
                      <th><div className='clickable-text'
                        onClick={addSynonym} size="sm">add</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {organization.alt_names.map((ae, i) => {
                      return <tr key={i}>
                        <td>
                          <input
                            className='form-ctrl'
                            type='text'
                            value={ae.alt_org_name}
                            onChange={(e) => setSynonym(e.target.value, i)}
                          />
                        </td>
                        <td>
                          <SimpleSelect
                            options={partners}
                            onChange={(partner) => setPartner(partner, i)}
                            value={ae.partner}
                          />
                        </td>
                        <td className='right-align'>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => deleteSynonym(i)}>-</Button>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </Table>
              </Col>
              {errors.length > 0 ?
                <div>
                  <div className="error">Errors:</div>
                  {
                    errors.map((e) => <li className="error">{e}</li>)
                  }
                </div>
                : null}
              <ButtonToolbar className='editor-btn end-align'>
                <ButtonGroup>
                  <Button className='button-secondary m-i-end-02' variant="secondary" onClick={closePopup} >Close</Button>{' '}
                </ButtonGroup>
                <ButtonGroup>
                  <Button className='btn-primary m-i-end-02' type="submit" onClick={handleSubmit}>Submit</Button>{' '}
                </ButtonGroup>
                <ButtonGroup>
                  <Button className='btn-primary' onClick={checkOrganization}>Check Organization</Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Row>
          </Modal.Body>
          : null
      }
    </Modal>
  )
}

export default OrganizationEditor;
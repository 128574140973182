import React from 'react'; 
import ReactTblSelectEditCell from "../../react-tbl-editable-cells/ReactTblSelectEditCell";
import PMTCONSTANTS from "../pmtConstants";
import ReactTblTextAreaEditCell from "../../react-tbl-editable-cells/ReactTblTextAreaEditCell";
import TableCellDropDown from "./TableCellDropDown";
import TableDatePickerCell from "./TableDatePickerCell";
import EnumSelector from "../../Alchemy/EnumSelector";


const PMTtableConfig = {
  'MARKET_ACTIONS': (app, handleShowModal, updatedData, organization, assigneesList, priorities, key, hmfCategory=null, vaccineRoadmap=null, uniqueObjectives=null) => {
    //gets a list of available assignees for the assignee field


    let appName = app && app.fields && app.fields.apptype ? app.fields.apptype : app

    return [
      {
        header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_8,
        className: 'default-col',
        accessor: rowObj => {
          return (
            <EnumSelector
              className="enum-dropdown-select"
              enum_type={PMTCONSTANTS.PMT_ENUMS[appName].customField_8}
              enumData={vaccineRoadmap} 
              doAPICall={true}
              apiKey={key} 
              isMulti={false} 
              disableCreate={true} 
              isClearable={true}
              value={rowObj.customField_8 ? rowObj.customField_8 : null}
              fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.customField_8}
              onChange={(e) => updatedData(rowObj.item_id, 'customField_8', e.label)}
            />

          )
        },
      },
      {
        header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_1,
        className: 'wide-col',
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              initialValue={rowObj.customField_1}
              rowID={rowObj.item_id}
              column={'customField_1'}
              showLinkIcon={false}
              updateMyData={updatedData}
              autoComplete={true}
              suggestions={uniqueObjectives} 

            />
          )
        },
      },
      {
        header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_2,
        className: 'wide-col',
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              className='wide-col'
              initialValue={rowObj.customField_2}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'customField_2'}
              showLinkIcon={false}
              updateMyData={updatedData}
              autoComplete={false}
            />
          )
        },
      },
      {
        header: PMTCONSTANTS.LIST_ITEM_NAME[appName],
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              initialValue={rowObj.item_name}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'item_name'}
              showLinkIcon={true}
              handleShowItemModal={handleShowModal}
              updateMyData={updatedData}
              autoComplete={false}
            />
          )
        },
        className: 'intervention-col wide-col',
      },
      {
        header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_3,
        accessor: rowObj => {
          return (
            <div className='table-dropdown-container'>
            <EnumSelector
              className="enum-dropdown-select"
              enum_type={PMTCONSTANTS.PMT_ENUMS[appName].customField_3}
              enumData={hmfCategory}
              apiKey={key}
              doAPICall={true}
              isMulti={true}
              disableCreate={false}
              value={rowObj.customField_3 ? rowObj.customField_3 : []}
              isClearable={true}
              fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.customField_3}
              onChange={(e) => updatedData(rowObj.item_id, 'customField_3', e)}
            />
          </div>
          )
        },
        className: 'hmfCategory default-col',
      },
      {
        header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_4,
        className: 'default-col',
        accessor: rowObj => {
          return (
            <ReactTblSelectEditCell
              className='table-dropdown-select'
              initialValue={rowObj.customField_4 ? {label: rowObj.customField_4, value: rowObj.customField_4 }: ''} 
              column={'customField_4'}
              rowID={rowObj.item_id}
              updateMyData={updatedData}
              selectionValues={organization}
              forName='vaccine'
            />
          )
        },
      },
      {
        header: 'Assignee',
        className: 'default-col',
        accessor: rowObj => {
          return (
            <TableCellDropDown
              className='table-dropdown-select'
              initialValue={rowObj.assigned_to ? {label: rowObj.assigned_to, value: rowObj.assigned_to} : null}
              rowID={rowObj.item_id}
              column={'assigned_to'}
              updateMyData={updatedData}
              selectionValues={assigneesList}
              forName='assigned_to'
              customField={true} />
          )
        },
      },
      {
        header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_5,
        className: 'default-col',
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              className='p-b-start-02'
              initialValue={rowObj.customField_5}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'customField_5'}
              showLinkIcon={false}
              updateMyData={updatedData}
              autoComplete={false}
            />
          )
        },
      },
      {
        header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_6,
        className: 'default-col',
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              className='p-b-start-02'
              initialValue={rowObj.customField_6}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'customField_6'}
              showLinkIcon={false}
              updateMyData={updatedData}
              autoComplete={false}
            />
          )
        },
      },
      {
        header: 'Priority',
        className: 'default-col',
        accessor: rowObj => {
          return (
            <TableCellDropDown
              className='table-dropdown-select'
              initialValue={rowObj.priority ? {label: rowObj.priority, value: rowObj.priority} : null}
              rowID={rowObj.item_id}
              column={'priority'}
              updateMyData={updatedData}
              selectionValues={priorities}
              forName='priority'
              customField={true} />
          )
        },
      }, {
        header: 'Due Date',
        className: 'default-col',
        accessor: rowObj => {
          return (
            <TableDatePickerCell
              initialValue={rowObj.due_date}
              rowID={rowObj.item_id}
              column={'due_date'}
              updateMyData={updatedData}
            />
          )
        }
      },
      {
        header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_7,
        className: 'default-col',
        accessor: rowObj => {
          return (
            <ReactTblSelectEditCell
              className='table-dropdown-select'
              initialValue={rowObj.customField_7 ? {label:rowObj.customField_7, value:rowObj.customField_7} : null}
              rowID={rowObj.item_id}
              column={'customField_7'}
              updateMyData={updatedData}
              selectionValues={PMTCONSTANTS[appName].CUSTOM_FIELD_7_SELECT}
              forName='dueDateTypes' />
          )
        },
      },
      {
        header: 'Status',
        className: 'default-col',
        accessor: rowObj => {
          return (
            <TableCellDropDown
              className='table-dropdown-select'
              initialValue={rowObj.status ? {label: rowObj.status, value: rowObj.status} : null}
              rowID={rowObj.item_id}
              column={'status'}
              updateMyData={updatedData}
              customField={true}
              selectionValues={PMTCONSTANTS.STATUS_SELECT}
              forName='statuses' />
          )
        },
      },
      {
        header: 'Notes',
        className: 'default-col intervention-col',
        accessor: rowObj => {
          return (
            <ReactTblTextAreaEditCell
              className='pt-2'
              initialValue={rowObj.customField_9}
              handleShowIntModal={handleShowModal}
              rowID={rowObj.item_id}
              column={'customField_9'}
              showLinkIcon={false}
              updateMyData={updatedData}
              autoComplete={false}
            />
          )
        },
      }
    ]
  },
  
  //DEMAND HEALTH ------------------------------------------------------- DEMAND HEALTH -------------------------------------------- DEMAND HEALTH -------------------------------------------
  'OBJECTIVES': (app, handleShowModal, updatedData, organization=null, memberList=null, priorities=null, key, enumData=null) => {
      let appName = app && app.fields && app.fields.apptype ? app.fields.apptype : app;
  
        let retArr = [
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.list_name,
            accessor: rowObj => {
              return (
                <EnumSelector
                  enum_type={PMTCONSTANTS.PMT_ENUMS[appName].list_name}
                  enumData={enumData? enumData: null}
                  apiKey={key}
                  isMulti={false}
                  disableCreate={false}
                  value={rowObj.list_name ? rowObj.list_name : []}
                  isClearable={true}
                  fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.list_name}
                  onChange={(e) => updatedData(rowObj.item_id, 'list_name', e)}
                />
              )
            },
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_1,
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_1}
                  handleShowIntModal={handleShowModal}
                  rowID={rowObj.item_id}
                  column={'customField_1'}
                  showLinkIcon={false}
                  updateMyData={updatedData}
                />
              )
            },
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_2,
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_2}
                  rowID={rowObj.item_id}
                  column={'customField_2'}
                  updateMyData={updatedData}
                />
              )
            },
            className: 'wide-col',
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_3,
            accessor: rowObj => {
              return (
                <EnumSelector
                  className='hmfCategory'
                  enum_type={PMTCONSTANTS.PMT_ENUMS[appName].customField_3}
                  enumData={enumData? enumData: null}
                  apiKey={key}
                  isMulti={false}
                  disableCreate={false}
                  value={rowObj.customField_3 ? rowObj.customField_3 : []}
                  isClearable={true}
                  fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.customField_3}
                  onChange={(e) => updatedData(rowObj.item_id, 'customField_3', e)}
                />
              )
            },
            className: 'hmfCategory',
          },
          {
            header: 'Priority',
            accessor: rowObj => {
              return (
                <EnumSelector
                  enum_type={PMTCONSTANTS.PMT_ENUMS[appName].priority}
                  enumData={enumData ? enumData: null}
                  apiKey={key}
                  isMulti={false}
                  disableCreate={false}
                  value={rowObj.priority ? rowObj.priority : []}
                  isClearable={true}
                  fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.priority}
                  onChange={(e) => updatedData(rowObj.item_id, 'priority', e)}
                />
              )
            },
          },
          {
            header: 'Status',
            accessor: rowObj => {
              return (
                <EnumSelector
                enum_type={PMTCONSTANTS.PMT_ENUMS[appName].status}
                enumData={enumData ? enumData: null}
                apiKey={key}
                isMulti={false}
                disableCreate={false}
                value={rowObj.status ? rowObj.status : []}
                isClearable={true}
                fieldName={PMTCONSTANTS[appName].CUSTOM_FIELD_NAME.status}
                onChange={(e) => updatedData(rowObj.item_id, 'status', e)}
              />
              )
            },
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_4,
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_4}
                  handleShowIntModal={handleShowModal}
                  rowID={rowObj.item_id}
                  column={'customField_4'}
                  showLinkIcon={false}
                  updateMyData={updatedData}
                />
              )
            },
          },
          {
            header: PMTCONSTANTS[appName].CUSTOM_KEYS.customField_5,
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_5}
                  handleShowIntModal={handleShowModal}
                  rowID={rowObj.item_id}
                  column={'customField_5'}
                  showLinkIcon={false}
                  updateMyData={updatedData}
                />
              )
            },
          },
          {
            header: 'Due Date',
            accessor: rowObj => {
              return (
                <TableDatePickerCell
                  initialValue={rowObj.due_date}
                  row={rowObj.item_id}
                  column={'due_date'}
                  updateMyData={updatedData}
                />
              )
            }
          },
          {
            header: 'Comments',
            accessor: rowObj => {
              return (
                <ReactTblTextAreaEditCell
                  initialValue={rowObj.customField_9}
                  handleShowIntModal={handleShowModal}
                  rowID={rowObj.item_id}
                  column={'customField_9'}
                  showLinkIcon={false}
                  updateMyData={updatedData}
                />
              )
            },
            className: 'intervention-col',
          }
        ]
        return retArr;
    }, 
  }


export default PMTtableConfig;
import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';

import utils from '../../../utils';
import EnumSelector from '../EnumSelector';
import ObjectSelector from '../ObjectSelector';
import DoseSequenceEditor from './DoseSequenceEditor';
import CoverageEditor from './CoverageEditor';
import ExternalID from './ExternalId';
import Codeliveries from './Codeliveries';
import SimpleSelect from '../SimpleSelect';
import ExpandCollapse from '../../Layout/ExpandCollapse';
import ModalDynamic from '../../Layout/ModalDynamic';
import NumericInput from '../NumericInput';
import ConfirmationContent from '../ConfirmationContent';
import { copyActivity, deleteActivity } from './ceUtils';
import ToolTip from '../../Layout/ToolTip';
import { ArrowRightCircle, InfoCircleFill, Trash } from 'react-bootstrap-icons';

function ActivityEditor({ activityId, activityToCopy, deliveryId, apiKey,
  closePopup, userName, tooltip, getTooltipContentByKey, originalInfoSource, appClass = null }) {

  /* ---- State Variables ----- */
  const [activity, setActivity] = useState(null)
  const [childGeographies, setChildGeographies] = useState([]);
  const [productCharacteristics, setProductCharacteristics] = useState([]);
  const [vaccineGroup, setVaccineGroup] = useState(null);
  const [fieldsUpdated, setFieldsUpdated] = useState(new Set());
  const [aggregatedTargetPopulation, setAggregatedTargetPopulation] = useState(0);
  const [showWarning, setShowWarning] = useState(false)
  const [currentView, setCurrentView] = useState('editor')
  const [infoSource, setInfoSource] = useState(activity?.info_source);
  const [errors, setErrors] = useState({
    activity: [],
    coverage: [],
    externalIDs: [],
    doseSequences: [],
    phase: []
  });



  // sort dose_sequences by dose_sequence (1, 2, 3), not by default which is dose_sequence_id
  function sortDoseSequences(dose_sequences) {
    return [...dose_sequences].sort((a, b) => a.dose_sequence - b.dose_sequence);
  }


  useEffect(() => {
    async function fetchData() {
      // new activity
      if (!activityId && !activityToCopy) {
        setActivity({
          activity_id: null,
          geography_id: null,
          activity_type: null,
          delivery_method: null,
          intervention: null,
          gender: null,
          scope: null,
          activity_goal: null,
          target_population: null,
          info_source: null,
          info_source_date: null,
          info_source_detail: null,
          internal_notes: null,
          external_notes: null,
          user_updated: null,
          fields_updated: null,
          include: false,
          deleted: false,
          funding_sources: [],
          dose_sequences: [{
            dose_sequence: 1,
            phases: [{
              phase_id: null, activity_id: null, planned_start_year: null,
              actual_start_year: null, target_population: null, target_geographies: [],
              has_unknown_geo: false
            }]
          }],
          diseases: [],
          update_notes: null,
          source: null,
          delivery_id: deliveryId,
          delivery: {},
          coverage: [],
          external_ids: [],
          confidential: true
        });
      } else if (!activityId && activityToCopy) {
        // Copying activity
        setActivity(copyActivity(activityToCopy));
      } else {
        // Edit activity: pulls existing from db
        let activityResult = await utils.getData('get_activity', apiKey, "?activity_id=" + activityId);
        if (activityResult !== null) {
          if (activityResult?.dose_sequences) {
            // Sort dose_sequences by dose_sequence.
            activityResult.dose_sequences = sortDoseSequences(activityResult.dose_sequences);
            // Sort phases within each dose_sequence by start year and number them.
            activityResult.dose_sequences = sortAndNumberPhases(activityResult.dose_sequences);
          }
          setActivity(activityResult);
          setVaccineGroup(activityResult.intervention ? activityResult.intervention.vaccine_group : null);
        }
      }
    }

    fetchData();
  }, [apiKey, activityId, activityToCopy, deliveryId]);



  useEffect(() => {
    setInfoSource(activity?.info_source);
  }, [activity?.info_source]);


  // Sort and Number Phases by actual_start_year, actual_start_month, NULLS last
  // (DoseSequence 1 > phase 1, phase 2.  DoseSequence 2 > phase 3, phase 4)
  function sortAndNumberPhases(doseSequences) {
    // flatMap makes single array of all phases, so they're not separated by doseSequences
    let allPhases = doseSequences.flatMap(doseSequence =>
      doseSequence.phases.map(phase => ({
        ...phase,
        doseSequenceNumber: doseSequence.dose_sequence // keeps phase in original dose_sequence
      }))
    );

    allPhases.sort((a, b) => {
      // Handle null years by sorting them to the end
      if (a.actual_start_year === null) return 1;
      if (b.actual_start_year === null) return -1;
      if (a.actual_start_year !== b.actual_start_year) {
        return a.actual_start_year - b.actual_start_year;
      }

      // Handle null months by sorting them to the end within the same year
      if (a.actual_start_month === null) return 1;
      if (b.actual_start_month === null) return -1;
      return a.actual_start_month - b.actual_start_month;
    });

    // Number phases starting at 1
    allPhases.forEach((phase, index) => {
      phase.phaseNumber = index + 1; // camelCase not snake_case to identify that it's not in the database/part of the phase object
    });

    // Clear existing phases from dose sequences (prevents duplicates)
    doseSequences.forEach(doseSequence => {
      doseSequence.phases = [];
    });

    // Reassign phases back to their dose sequences 
    allPhases.forEach(phase => {
      const doseSequence = doseSequences.find(d => d.dose_sequence === phase.doseSequenceNumber);
      doseSequence.phases.push(phase);
    });

    //  Sort the phases within each doseSequence by phaseNumber
    doseSequences.forEach(doseSequence => {
      doseSequence.phases.sort((a, b) => a.phaseNumber - b.phaseNumber);
    });

    return doseSequences;
  }




  // -- Geographies 
  useEffect(() => {
    async function refreshGeographies() {
      if (!activity || !activity.geography_id) {
        return
      }
      let geographies = await utils.getData('get_geography_children', apiKey, "?parent_id=" + activity.geography_id)
      let formattedGeographies = geographies.map((g) => {
        return {
          label: g.admin1_name + (g.admin2_name ? ' -- ' + g.admin2_name : ''),
          value: g.geography_id
        }
      })
      setChildGeographies(formattedGeographies)
    }
    refreshGeographies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity?.geography_id, apiKey])

  // -- Product Characteristics
  useEffect(() => {
    async function refreshProductOptions() {
      var prodChars = []
      if (vaccineGroup) {
        prodChars = await utils.getData("get_product_characteristics",
          apiKey, "?vaccine_group=" + vaccineGroup)
      }

      setProductCharacteristics(prodChars)
    }
    refreshProductOptions()
  }, [vaccineGroup, apiKey])


  // -- Target Population
  useEffect(() => {
    const aggregatedValue = calculateAggregatedTargetPopulation(activity);
    setAggregatedTargetPopulation(aggregatedValue);
  }, [activity]); // Recalculate when activity changes





  /*-------------------------Delete Activity Warning Modal----------------------------------*/

  // show delete warning
  const showWarningModal = () => {
    setShowWarning(true)
  }

  // close delete warning
  const handleClose = () => {
    setShowWarning(false)
  }

  // add warning icon to dynamic buttons in modal
  const addWarningIcon = (name) => {
    return (
      <>
        <InfoCircleFill className='btn-icon' />{name}
      </>
    )
  }

  // add trash icon to dynamic buttons in modal
  const addTrashIcon = (name) => {
    return (
      <>
        {name}<Trash className='btn-icon' />
      </>
    )
  }

  // warning modal body content
  const getModalBody = () => {
    return (
      <div className='m-b-end-07'>
        <p className='body-med txt-primary'>Are you sure you want to delete <strong>Activity ID {activity?.activity_id}</strong>?</p>
      </div>
    )
  }


  // pop up alerting the user about the delete
  const deleteConfirmationModal = {
    title: addWarningIcon('Warning'),
    body: getModalBody(),
    app: 'Global Health Alchemy',
    subName: addTrashIcon('Delete')
  }



  /*-------------------------Submit form and new activity----------------------------------*/


  // Pre-submit validation and transition to confirmation view
  async function handlePreSubmit(e) {
    activity.user_updated = userName;
    activity.fields_updated = Array.from(fieldsUpdated).join(", ");

    e.preventDefault();
    e.stopPropagation();

    let activityErrors = [];
    let coverageErrors = [];
    let externalIDErrors = [];
    let phaseErrors = [];

    // Activity Errors
    if (!activity.scope) activityErrors.push("Scope is required");
    if (!activity.geography_id) activityErrors.push("Geography is required");
    if (!activity.intervention) activityErrors.push("Intervention must be selected");
    if (!activity.activity_type) activityErrors.push("Activity Type must be selected");
    if (!activity.info_source) activityErrors.push("Info Source must be selected");
    if (!activity.info_source_date) activityErrors.push("Info Source Date is required");

    // Coverage Errors
    activity?.coverage?.forEach((cov) => {
      if (!cov.coverage_type) coverageErrors.push('Coverage Type is required.');
    });

    // External ID Errors
    activity?.external_ids?.forEach((id) => {
      if (!id.external_data_source) externalIDErrors.push('External Source is required.');
      if (!id.external_data_source_id) externalIDErrors.push('External ID is required.');
    });

    // Iterating over dose sequences and phases
    activity?.dose_sequences?.forEach((ds, dsIndex) => {
      ds.phases.forEach((phase, phaseIndex) => {
        let currentPhaseErrors = [];
        if (!phase.phase_status) currentPhaseErrors.push('Phase Status must be selected');
        if (!phase.planned_start_year) currentPhaseErrors.push('Planned Start Year must be selected');
        if (!phase.actual_start_year) currentPhaseErrors.push('Actual Start Year must be selected');
        if (phase.actual_start_year && phase.actual_end_year && phase.actual_end_year < phase.actual_start_year) currentPhaseErrors.push('Actual End Year must be greater than or equal to Actual Start Year');
        if (phase.planned_start_year && phase.planned_end_year && phase.planned_end_year < phase.planned_start_year) currentPhaseErrors.push('Planned End Year must be greater than or equal to Planned Start Year');
        if (!phase.info_source) currentPhaseErrors.push('Info Source must be selected');
        if (!phase.info_source_date) currentPhaseErrors.push('Info Source Date must be selected');

        if (currentPhaseErrors.length > 0) {
          phaseErrors.push({ dsIndex, phaseIndex, messages: currentPhaseErrors });
        }
      });
    });

    // Set Errors
    setErrors({
      activity: activityErrors,
      coverage: coverageErrors,
      externalIDs: externalIDErrors,
      phase: phaseErrors
    });

    if (activityErrors.length > 0 || coverageErrors.length > 0 || externalIDErrors.length > 0 || phaseErrors.length > 0) {
      return;
    }
    setCurrentView('confirmation');
  }




  const handleSubmitWithConfirmation = async ({ updateNotes, updateSource }) => {
    activity.user_updated = userName;
    activity.fields_updated = Array.from(fieldsUpdated).join(", ");
    activity.update_notes = updateNotes;
    activity.source = updateSource;

    const result = await utils.upsert('upsert_activity', apiKey, activity);
    if (result !== null) {
      closePopup();
    }
  };



  // Print State button: displays activity data in console
  function printState() {
    console.log(activity)
  }


  function addFieldUpdated(field) {
    const tempFieldUpdates = new Set(fieldsUpdated)
    tempFieldUpdates.add(field);
    setFieldsUpdated(tempFieldUpdates);
  }

  function setGeography(geography) {
    addFieldUpdated('geography');
    setActivity({
      ...activity,
      geography_id: geography ? geography.geography_id : null,
      geography: geography
    })
  }

  function setIntervention(intervention) {
    addFieldUpdated('intervention');
    setActivity({
      ...activity,
      intervention_id: intervention.intervention_id,
      intervention: intervention,
      diseases: []
    })

    setVaccineGroup(intervention.vaccine_group)
  }



  // ------------ handleUpdate ----------- //
  function handleUpdate(value, field) {
    addFieldUpdated(field);
    const temp = { ...activity, [field]: value };
    setActivity(temp);
  }



  /*-------------------------Add Phase ----------------------------------*/


  function addDose() {
    const temp = [...activity.dose_sequences];
    temp.push({
      phases: []
    })
    for (let i = 0; i < temp.length; i++) {
      temp[i].dose_sequence = i + 1;
    }
    setActivity({ ...activity, dose_sequences: temp })
  }

  function handleDoseSequenceChange(ds, i) {
    const temp = [...activity.dose_sequences];
    temp[i] = ds;
    setActivity({ ...activity, dose_sequences: temp });
  }

  function handleDoseSequenceDelete(i) {
    if (!window.confirm("This will delete a dose sequence. Are you sure you want to proceed?")) {
      return
    }
    const temp = [...activity.dose_sequences]
    temp.splice(i, 1)
    for (let i = 0; i < temp.length; i++) {
      temp[i].dose_sequence = i + 1;
    }
    setActivity({ ...activity, dose_sequences: temp })
  }

  function setFundingsource(e) {
    addFieldUpdated('funding_source')
    setActivity({ ...activity, funding_sources: e.map((s) => { return { source: s.label } }) })
  }


  function setActivityDisease(e) {
    addFieldUpdated('disease');
    setActivity({ ...activity, diseases: e.map((d) => { return { disease: d } }) })
  }

  // -- Calculate Target Population --

  function calculateAggregatedTargetPopulation(activity) {
    const allPhases = activity?.dose_sequences?.flatMap(doseSequence => doseSequence.phases) || [];
    const targetPopulations = allPhases.map(phase => phase.target_population);
    const allSame = targetPopulations.every((val, i, arr) => val === arr[0]);
    if (allSame && targetPopulations.length > 0) {
      return targetPopulations[0];
    } else {
      return targetPopulations.reduce((total, current) => total + Number(current), 0);
    }
  }



  // -------------------- Confirmation Page --------------------------------------------//





  // go back button goes from confirmation page back to the tab view
  const handleGoBack = () => {
    setCurrentView('editor');
  }

  // Confirmation Page Header
  function renderConfirmationHeader(activity) {
    return (
      <div className="modal-title-group">
        <Modal.Title className="body-large p-b-end-02"><strong>Activity ID: </strong><span>{activity.activity_id ?? ''}</span></Modal.Title>
        <Modal.Title className="body-large p-b-end-02"><strong>Activity Type: </strong><span>{activity.activity_type}</span></Modal.Title>
        <Modal.Title className="body-large p-b-end-02">
          <strong>External ID: </strong>
          {activity.external_ids.map((item, index) => (
            <span key={index} className="external-id">
              {item.external_data_source_id}
              {index < activity.external_ids.length - 1 && ', '}
            </span>
          ))}
        </Modal.Title>

        <Modal.Title className="body-large p-b-end-02"><strong>Country: </strong><span>{activity.geography.geography_name}</span></Modal.Title>
        <Modal.Title className="body-large p-b-end-02"><strong>Intervention: </strong><span>{activity.intervention.intervention}</span></Modal.Title>

        <Modal.Title className="body-large p-b-end-02"><strong>Info Source: </strong><span>{activity.info_source}</span></Modal.Title>
        <Modal.Title className="body-large p-b-end-02"><strong>Info Source Date: </strong><span>{activity.info_source_date}</span></Modal.Title>

        <Modal.Title className="body-large p-b-end-02"><strong>User Updated: </strong><span>{activity.user_updated}</span></Modal.Title>
        <Modal.Title className="body-large p-b-end-02"><strong>Fields Updated: </strong><span>{activity.fields_updated}</span></Modal.Title>


      </div>
    );
  }


  /*-------------------------Modal UI----------------------------------*/


  return (
    <>
      <Modal dialogClassName='modal-90w' className={appClass} show={true} scrollable>
        <Modal.Header>
          <Modal.Title className='display-flex'>Activity Editor
            {activityId === null ?
              <Button className='btn-primary btn-secondary m-i-start-04' variant="secondary" onClick={closePopup}>Back</Button> : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentView === 'editor' && (
            <>
              {activity !== null && (
                <>
                  <ExpandCollapse editorName="Activity" showWarningModal={showWarningModal}>
                    <div className="input-text">
                      <Row>
                        <Col>
                          <p><strong>Delivery ID: </strong>{activity?.delivery_id}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p><strong>Activity ID: </strong>{activity?.activity_id}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p><strong>Target Population: </strong>{utils.formatNumberWithCommas(aggregatedTargetPopulation)}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p><strong>Original Info Source: </strong>{originalInfoSource}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p><strong>User Updated: </strong>{activity?.user_updated}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p><strong>Fields Updated: </strong>{activity?.fields_updated}</p>
                        </Col>
                      </Row>
                    </div>


                    {/* First Row*/}
                    <Row>
                      {/* Scope */}
                      <Col lg={2} md={6} sm={12}>
                        <div className="input-text">Scope *</div>
                        <EnumSelector
                          className="select-max-100"
                          enum_type="ce_scope"
                          apiKey={apiKey}
                          disableCreate={true}
                          value={activity.scope}
                          onChange={(e) => handleUpdate(e.label, 'scope')}
                        />
                      </Col>

                      {/* Geography */}
                      <Col lg={2} md={6} sm={12}>
                        <div className="input-text">Geography *</div>
                        <ObjectSelector
                          apiKey={apiKey}
                          obj={activity.geography}
                          onChange={setGeography}
                          isClearable={true}
                          type="geography"
                        />
                      </Col>

                      {/* Intervention */}
                      <Col lg={3} md={6} sm={12}>
                        <div className="input-text">
                          Intervention * 
                          <div className='display-inline-block m-i-start-02'>
                            <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_intervention')} />
                          </div>
                        </div>
                        <ObjectSelector
                          apiKey={apiKey}
                          obj={activity.intervention}
                          type="interventions"
                          onChange={setIntervention}
                        />
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <div className="input-text">Diseases</div>
                        {activity.intervention && activity.intervention.disease_group === 'PC-NTDs' ? (
                          <SimpleSelect
                            options={activity.intervention?.diseases?.map((d) => d.disease)}
                            value={activity.diseases?.map((d) => d.disease)}
                            onChange={setActivityDisease}
                            isMulti={true}
                          />
                        ) : (
                          <SimpleSelect
                            options={activity.intervention?.diseases?.map((d) => d.disease)}
                            value={activity.diseases?.map((d) => d.disease)}
                            onChange={setActivityDisease}
                            isMulti={true}
                            disabled={true}
                          />
                        )
                        }
                      </Col>

                      {/* Activity Type */}
                      <Col lg={2} md={6} sm={12}>
                        <div className="input-text">
                          Activity Type
                          <div className='display-inline-block m-i-start-02'>
                            <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_activity_type')} />
                          </div>
                        </div>
                        <EnumSelector
                          className="select"
                          enum_type="activity_type"
                          apiKey={apiKey}
                          disableCreate={true}
                          value={activity.activity_type}
                          onChange={(e) => handleUpdate(e.label, 'activity_type')}
                        />
                      </Col>
                    </Row>

                    {/* Second Row */}
                    <Row>

                      {/* Funding Sources */}
                      <Col lg={2} md={6} sm={12}>
                        <div className="input-text">Funding Sources</div>
                        <EnumSelector
                          className="select"
                          enum_type="funding_source"
                          apiKey={apiKey}
                          value={activity.funding_sources.map((fs) => fs.source)}
                          clearAfterSelect={false}
                          isMulti={true}
                          onChange={setFundingsource}
                        />
                      </Col>

                      <Col lg={2} md={6} sm={12}>
                        <div className="input-text">Gender</div>
                        <EnumSelector
                          className="select-max-100"
                          enum_type="gender"
                          apiKey={apiKey}
                          disableCreate={true}
                          value={activity.gender}
                          onChange={(e) => handleUpdate(e.label, 'gender')}
                        />
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <Row>
                          <Col md={6} sm={6}>
                            <div className="input-text">Min Age</div>
                            <NumericInput
                              className="textarea"
                              placeholder="##"
                              value={activity.min_age}
                              onChange={(value) => handleUpdate(value, 'min_age')}
                              allowDecimals={true}
                              allowCommas={true}
                            />
                          </Col>

                          <Col md={6} sm={6}>
                            <div className="input-text">Time Unit</div>
                            <EnumSelector
                              className="select-max-100"
                              enum_type="time_unit"
                              apiKey={apiKey}
                              disableCreate={true}
                              value={activity.min_age_unit}
                              onChange={(e) => handleUpdate(e.label, 'min_age_unit')}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <Row>
                          <Col md={6} sm={6}>
                            <div className="input-text">Max Age</div>
                            <NumericInput
                              className="textarea"
                              placeholder="##"
                              value={activity.max_age}
                              onChange={(value) => handleUpdate(value, 'max_age')}
                              allowDecimals={true}
                              allowCommas={true}
                            />
                          </Col>

                          <Col md={6} sm={6}>
                            <div className="input-text">Time Unit</div>
                            <EnumSelector
                              className="select-max-100"
                              enum_type="time_unit"
                              apiKey={apiKey}
                              disableCreate={true}
                              value={activity.max_age_unit}
                              onChange={(e) => handleUpdate(e.label, 'max_age_unit')}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={2} md={6} sm={12}>
                        <div className="input-text">Delivery Method</div>
                        <EnumSelector
                          className="select"
                          enum_type="delivery_method"
                          apiKey={apiKey}
                          disableCreate={true}
                          value={activity.delivery_method}
                          onChange={(e) => handleUpdate(e.label, 'delivery_method')}
                        />
                      </Col>

                    </Row>

                    {/* Third Row:*/}
                    <Row>

                      <Col lg={2} md={6} sm={12}>
                        <div className="input-text">Info Source Date *</div>
                        <input
                          className="form-ctrl"
                          type="date"
                          value={activity.info_source_date}
                          onChange={(e) => handleUpdate(e.target.value, 'info_source_date')}
                        />
                      </Col>

                      <Col lg={2} md={6} sm={12}>
                        <div className="input-text">
                          Info Source
                          <div className='display-inline-block m-i-start-02'>
                            <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_info_source')} />
                          </div>
                        </div>
                        <EnumSelector
                          className="select"
                          enum_type="info_source"
                          apiKey={apiKey}
                          disableCreate={false}
                          value={activity.info_source}
                          onChange={(e) => {
                            setInfoSource(e.label);
                            handleUpdate(e.label, 'info_source');
                          }}
                        />
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <div className="input-text">
                          Internal Notes
                          <div className='display-inline-block m-i-start-02'>
                            <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_internal_notes')} />
                          </div>
                        </div>
                        <textarea
                          className="textarea"
                          value={activity.internal_notes}
                          onChange={(e) => handleUpdate(e.target.value, 'internal_notes')}
                        />
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                      <div className="input-text">
                          External Notes
                          <div className='display-inline-block m-i-start-02'>
                            <ToolTip className="app-tooltip" tooltip={getTooltipContentByKey(tooltip, 'ce_external_notes')} />
                          </div>
                      </div>
                        <textarea
                          className="textarea"
                          value={activity.external_notes}
                          onChange={(e) => handleUpdate(e.target.value, 'external_notes')}
                        />
                      </Col>

                      <Col lg={2} md={6} sm={12}>
                        <div className="d-flex flex-column m-b-start-07">
                          <div className="d-flex align-items-end justify-content-start mb-2">
                            <Form.Check
                              className="me-2"
                              checked={activity.confidential}
                              onChange={() => handleUpdate(!activity.confidential, 'confidential')}
                            />
                            <div className="basic-text">Confidential?</div>
                          </div>

                          <div className="d-flex align-items-end justify-content-start">
                            <Form.Check
                              className="me-2 m-b-start-02"
                              checked={activity.include}
                              onChange={() => handleUpdate(!activity.include, 'include')}
                            />
                            <div className="basic-text">Include?</div>
                          </div>
                        </div>
                      </Col>


                    </Row>


                    {errors.activity && errors.activity.length > 0 && (
                      <ul className="error-list">
                        {errors.activity.map((error, index) => (
                          <li key={index} className="error">
                            {error}
                          </li>
                        ))}
                      </ul>
                    )}


                    <ExternalID
                      externalIds={activity.external_ids}
                      onChange={(e) => handleUpdate(e, 'external_ids')}
                      apiKey={apiKey}
                      tooltip={tooltip}
                      getTooltipContentByKey={getTooltipContentByKey}
                      errors={errors.externalIDs}
                    />


                  </ExpandCollapse>


                  <ExpandCollapse
                    editorName='Phase'>
                    {
                      activity?.dose_sequences?.map((ds, dsIndex) => {
                        return (
                          <DoseSequenceEditor
                            key={dsIndex}
                            dsIndex={dsIndex}
                            doseSequence={ds}
                            onChange={(e) => handleDoseSequenceChange(e, dsIndex)}
                            onDelete={() => handleDoseSequenceDelete(dsIndex)}
                            apiKey={apiKey}
                            childGeographies={childGeographies}
                            prodChars={productCharacteristics}
                            userName={userName}
                            tooltip={tooltip}
                            getTooltipContentByKey={getTooltipContentByKey}
                            gender={activity.gender}
                            minAge={activity.min_age}
                            maxAge={activity.max_age}
                            minAgeUnit={activity.min_age_unit}
                            maxAgeUnit={activity.max_age_unit}
                            geographyId={activity.geography_id}
                            errors={errors.phase}
                            appClass={appClass}
                          />

                        )
                      }
                      )
                    }
                  </ExpandCollapse>
                  <Button className='btn-primary button-primary m-b-start-02' onClick={addDose}>Add Dose</Button>
                </>
              )}


              <Codeliveries
                userName={userName}
                apiKey={apiKey}
                parentActivity={activity}
                tooltip={tooltip}
                getTooltipContentByKey={getTooltipContentByKey}
                errors={errors.activity}
                appClass={appClass}
              />
              <CoverageEditor
                coverage={activity?.coverage}
                onChange={(e) => handleUpdate(e, 'coverage')}
                apiKey={apiKey}
                tooltip={tooltip}
                getTooltipContentByKey={getTooltipContentByKey}
                errors={errors.coverage}
                appClass={appClass}
              />



              <div className='editor-btn'>
                <Button className='btn-primary button-primary' variant="secondary" onClick={closePopup}>Close</Button>{' '}
                <Button className='btn-primary button-primary' type="submit" variant="primary" onClick={handlePreSubmit}>Next Step <ArrowRightCircle /></Button>{' '}
                <Button className='btn-primary button-primary' variant="primary" onClick={printState}>Check Activity</Button>
              </div>
            </>
          )}

          {currentView === 'confirmation' && (
            <ConfirmationContent
              editorName='activity'
              apiKey={apiKey}
              handleUpdate={handleUpdate}
              getTooltipContentByKey={getTooltipContentByKey}
              updateNotes={activity?.update_notes}
              updateSource={activity.info_source}
              infoSource={infoSource}
              onSubmit={handleSubmitWithConfirmation}
              onGoBack={handleGoBack}
              renderContentSection={() => renderConfirmationHeader(activity)}
            />
          )}
        </Modal.Body>
      </Modal>
      <ModalDynamic
        classes={{ modal: 'warning-border' }}
        show={showWarning}
        content={deleteConfirmationModal}
        handleClose={handleClose}
        submitRequest={() => deleteActivity(activity, apiKey, setShowWarning, closePopup, utils.upsert)}
      />
    </>
  )
}

export default ActivityEditor;
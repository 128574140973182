import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import utils from '../../utils';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import useScrollToLocation from '../useScrollToLocation';
import { useMsal } from '@azure/msal-react';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import ArticleItem from './ArticleItem';
import RelatedArticleTeaser from '../Layout/RelatedArticleTeaser';


function ArticleDisplay({tenant}) {
    
    let { storyID } = useParams();
    let [article, setArticle ] = useState();
    let [loadingArticle, setLoadingArticle] = useState(true);
    
    const { accounts } = useMsal();

    useScrollToLocation();

    const sendTrackingInfo = async (artToSend, userAcc) => {
        let artTags = utils.getTagsArray(artToSend.fields.tags)
        let newDate = new Date();

        await utils.upsert('insert_content_log', process.env.REACT_APP_DB_PUBLIC_KEY, 
                {
                    user: userAcc.username,
                    tags: artTags,
                    title: artToSend.fields.title,
                    content_id: artToSend.contentID,
                    access_date: newDate,
                    accessing_site:'cx-linksbridge'
                }
            )
    }
    
    const getArticle = async (storyID) => {

        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?id=${storyID}`, {});

        let jsonresponse = await response.json();
        setArticle(jsonresponse);

        sendTrackingInfo(jsonresponse, accounts[0]);
    }

    useEffect( () => {
        setLoadingArticle(true);
        try {
            getArticle(storyID);
            setLoadingArticle(false);
        } catch {
            setLoadingArticle(false);
        }
    // eslint-disable-next-line  
    },[storyID]);

    return (
        !loadingArticle && article ?
            <>
                <Col>
                    <BreadCrumbBar 
                        appName="NEWS" 
                        item={article} 
                        displayFieldName="title" />
                </Col>
                <ArticleItem
                    workspace={tenant}
                    article={article.fields} />
                
                <Row className='related-news-row'>
                    <Col className='m-b-start-05 '>
                        <RelatedArticleTeaser
                            tenant={tenant}
                            entity={article}
                            entityType={'company-profiles'}
                            header='Related News & Company Intelligence'
                            tagQuery={true}
                        />
                    </Col>
                </Row>
            </>
            :
            <ListSkeletonLoader preview={false} />
    )
}

export default ArticleDisplay

import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap'
import { useMsal } from '@azure/msal-react';
import { models } from "powerbi-client";
import { PowerBIEmbed } from 'powerbi-client-react';
import utils from '../utils';

import '../styles/components/_pbi-report.scss';

function PBIEmbeddedViz(props) {

    let [ embedData, setEmbedData ] = useState();
    let [ loadingEmbed, setLoadingEmbed ] = useState(true);
    let { loadingError, setLoadingError } = useState();

    let { instance, accounts } = useMsal();

    const loginRequest = {
        scopes: ["User.Read"]
    };

    //construct filter if exists on config


    const getEmbedData = async () => {
        let tokenForUse = await utils.getAccessToken(instance, accounts[0], loginRequest);
        let resJSON;
        try {
            let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/bi-service?reportid=${props.reportID}&datasetid=${props.datasetID}&reportType=${props.reportType || 'report'}`,{
                method: 'GET',
                headers: { "Authorization": "Bearer "+ tokenForUse.accessToken }
            });
    
            resJSON = await res.json();
            
            setEmbedData(resJSON);
        } catch (err) {
            setLoadingError('Sorry, there was an issue with this visual.  Please refresh your browser, and if it continues, contact support by clicking the feedback link on the far left hand side of the screen.')
        }
    }

    useEffect( ()=> {
        setLoadingEmbed(true);
        getEmbedData();
        setLoadingEmbed(false);

        return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reportID])

    return (
        loadingEmbed?
            <Spinner animation='border' />
        :
            embedData?
                <PowerBIEmbed 
                    embedConfig = {{
                        type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                        id: props.reportID,
                        embedUrl: embedData.embedUrl,
                        accessToken: embedData.token,
                        
                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed - was models.TokenType.Embed
                        filters: props.filters? props.filters : [],
                        settings: {
                            zoomLevel: props.zoomLevel? props.zoomLevel : 1,
                            layoutType: props.layoutType ? props.layoutType : models.LayoutType.Custom,
                            customLayout: {
                                pageSize: {
                                    type: props.pageSizeType ? props.pageSizeType : models.PageSizeType.Custom,
                                    width: props.initWidth,
                                    height: props.initHeight
                                }
                            },
                            displayOption: props.displayOption ? props.displayOption : models.DisplayOption.FitToWidth,
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                },
                                pageNavigation: {
                                    visible: false
                                }
                            }
                        }
                        }}
                        cssClassName={"pbi-report"} />
                        
            : 
                loadingError ?
                    <p>{loadingError}</p>
                : null
            
    )
}

export default PBIEmbeddedViz
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import utils from '../../utils';
import Pagination from 'rc-pagination';

import { LinkContainer } from 'react-router-bootstrap';

function CENewsList({ fullNewsList, preview }) {
    
    
    const [curPage, setCurPage] = useState(1);
    const NUMBER_PER_PAGE = 5;
    
    const handlePageClick = (num) => {
        setCurPage(num);
    }

    const getBodyHTML = (artItem) => {
        if(artItem.fields && artItem.fields.previewText && artItem.fields.previewText.length > 1) {
          return artItem.fields.previewText;
        }
        
        if (artItem && artItem.highlight && artItem.highlight.length > 0) {
          return artItem.highlight[0];
        }
        else {
          if (artItem.fields && artItem.fields.body) {
            return artItem.fields.body.substr(0, 300)+'...'
          } 
        } 
  
        return '';
      }

    const convertItemToTeaser = (items) => {
        
        return items.map( (item) => {
        
            return {
                fields: {
                    title: item.title.rendered,
                    body: item.content.rendered,
                    datepublished: item.date,
                },
                contentID: item.id
            }

        });
    }

    const getPageOfResults = ( originalList, pageNumber, numberInPage ) => {
        let startOfSlice = (pageNumber-1) * numberInPage;
        let endOfSlice = startOfSlice + numberInPage;
        let slicedList = originalList.slice(startOfSlice, endOfSlice);
        return convertItemToTeaser(slicedList);
    };

    

    return (
        <>
            {
                fullNewsList && fullNewsList.length > 0 ?
                    getPageOfResults(fullNewsList, curPage, NUMBER_PER_PAGE).map((thisItem, idx) => {
                        return (
                            <Row className="app-list-item">
                                <p className='article-date secondary-link-plain'>{utils.getDateString(thisItem.fields.datepublished, 'long')}</p>
                                <LinkContainer to={`/ce_news/${thisItem.contentID}`}>
                                    <h3 className='add-pointer'>{thisItem.fields.title ? thisItem.fields.title : ''}</h3>
                                </LinkContainer>
                                <p dangerouslySetInnerHTML={{__html: getBodyHTML(thisItem) }}></p>
                            </Row>
                        )
                    })
                
                : null
            }
            <div className="text-center">
                <Pagination
                    total={fullNewsList.length}
                    onChange={(item) => handlePageClick(item)}
                    current={curPage}
                />
            </div>
        </>
    )
}

export default CENewsList
import { useState, useCallback, useEffect, useRef } from "react";
import { Form } from 'react-bootstrap'
import DataFilter from "./DataFilter";
import '../../styles/layout/_layout.scss';


const createHeaders = (headers) => {
  // Augments the passed in headers with a useRef
  // The useRef holds the width.
  if(!headers){
    return
  }
  const headerDict={}
  headers.forEach(item => {
    item.ref=useRef()
    headerDict[item.title] = item
  });
  return headerDict;
};


function renderData(d){
  return <>
    {d===true||d===false?
    <Form.Check checked={d} disabled={true}/>:
    <span>{d}</span>}
    </>
};

const MIN_CELL_WIDTH = 120

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/resizable-tables-with-react-and-css-grid
 */

/*
  Specifics about this implentation.
  This is really designed to JUST be a child component of SearchTable. It is not especially reusable. It would result in a busier,
  more complex file if they were merged.

  ARGUMENTS
  headers: a list in the form of the metaData presented to SearchTable
  filteredHeaders: of the master list of headers, just those that should be displayed (enables dynamic column inclusion).
  filteredData: all data that matches the filter criteria in the table
  filters: the filters applied to the table. 
  handleFilterChange: a function passed in to handle changes to the filters
  handleWidthChange: a function passed in via props that deals with width changes (so that they can be stored for the user)
  pageData: the data ON THE page, not all data meeting the filter criteria
  columnData: data about the columns to be shown including the user-specified widths
  onRowClick: a function that gets called when a user double clicks a row
  isResizable: whether the table should allow resizing.
*/
const ResizableTable = ({ headers, filteredHeaders, filteredData, filters, handleFilterChange, 
      handleWidthChange, pageData, onRowClick, isResizable=true }) => {

  const [tableHeight, setTableHeight] = useState(500);
  const [activeIndex, setActiveIndex] = useState(null);
  const [filteredColumns, setFilteredColumns] = useState([])
  const tableElement = useRef(null);
  const colDict = createHeaders(headers);
    
  useEffect(()=>{
    // Gets the subset of columns that match the filtered headers
    function setColumnsFromHeaders(){
      if (!filteredHeaders){
        return
      }
      const tempFiltCols = filteredHeaders.map((fh)=>{return colDict[fh.title]})
      setFilteredColumns(tempFiltCols)
      const widthStr = filteredHeaders.map((fh)=>{return fh.width}).join(' ')
      tableElement.current.style.gridTemplateColumns = widthStr
    }
    setColumnsFromHeaders()
    // eslint-disable-next-line
  }, [filteredHeaders])
  

  useEffect(()=>{
    // For managing the change of table height.
    // For some reason this seems to set the table height to the second most recent render????
    function handleTableHeightChange(){
      if(!tableElement || !tableElement.current){
        return
      }
      setTableHeight(tableElement.current.offsetHeight);
    }
    handleTableHeightChange();
  }, [tableElement?.current?.offsetHeight])

  const mouseDown = (index) => {
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
    (e) => {
      const gridColumns = filteredColumns.map((col, i) => {
        if (i === activeIndex) {
          const width = e.clientX - col.ref.current.offsetLeft;
          if (width >= MIN_CELL_WIDTH) {
            return `${width}px`;
          }
        }
        return `${col.ref.current.offsetWidth}px`;
      });
      
      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(
        " "
      )}`;
    },
    [activeIndex, filteredColumns]
  );



  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", mouseUp);
    if(tableElement?.current?.style){
      handleWidthChange(tableElement.current.style.gridTemplateColumns.split(' '))
    }
    
  }, [setActiveIndex, mouseMove, handleWidthChange]);



  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
    };
  }, [activeIndex, mouseMove, mouseUp]);
  return (
    <div className={!isResizable ? 'overflow-x-scroll' : ''}>
      {filteredColumns ?
        <table className={isResizable ? " table table-striped search-table-dynamic" : "table table-striped search-table"} ref={tableElement}>
          <thead className={isResizable ? 'search-table-thead-dynamic' : 'search-table-thead'}>
            <tr className={isResizable ? "search-table-tr-dynamic" : 'search-table-tr'}>
              {filteredColumns.map((col, i) => (
                <th className='search-table-th' ref={col.ref} key={col.title}>
                  <span className="search-table-span"><strong>{col.title}</strong></span>
                  {
                    isResizable ?
                      <div
                        style={{ height: tableHeight }}
                        onMouseDown={() => mouseDown(i)}
                        className={`resize-handle ${activeIndex === i ? "active" : "idle"
                          }`
                        }
                      /> : null
                  }

                  {col.filterField ?
                    <DataFilter
                      filters={filters}
                      filteredData={filteredData}
                      field={col.filterField}
                      isMulti={col.isMulti}
                      asNumber={col.asNumber}
                      onChange={(e) => handleFilterChange(col.filterField, e)}
                    />
                    : null
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={isResizable ? "search-table-tbody-dynamic" : "search-table-tbody"}>
            {pageData.map((d) => {
              return <tr className={isResizable ? "search-table-tr-dynamic" : "search-table-tr"} onDoubleClick={() => onRowClick(d)}>
                {filteredHeaders.map((md) => {
                  return <td className={(d.deleted||d.lb_status==='Deleted') ? "deleted-search-table-td" : "search-table-td"}>
                    {renderData(d[md.dispField])}
                  </td>
                })
                }
              </tr>
            })}
          </tbody>
        </table>
        : null
      }

    </div>
  );
};

export default ResizableTable;

import React, { useState, useEffect, useRef } from 'react';
import { BoxArrowRight } from 'react-bootstrap-icons';
import { Row, Col } from 'react-bootstrap';

function ReactTblTextAreaEditCell({
  showLinkIcon=false,
  handleShowItemModal,
  rowID,
  initialValue,
  column,
  updateMyData,
  className=null,
  autoComplete=false,
  suggestions=null,
}) {
  const [myValue, setMyValue] = useState(initialValue || ''); // State to hold the current value of the textarea
  const textareaRef = useRef(null); // reference to textarea
  const [currentSuggestion, setCurrentSuggestion] = useState(''); // State to hold the current autocomplete suggestion

  // Function to dynamically adjust the height of the textarea based on its content
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height to auto to calculate new height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height to fit content
    }
  };

  // useEffect hook to set myValue when the rowID changes
  useEffect(() => {
    setMyValue(initialValue || ''); // Update myValue to the current initialValue
    adjustTextareaHeight(); // Adjust height based on content
  }, [initialValue, rowID]); // Only trigger when rowID changes

  // Function to handle changes in the textarea
  const onChange = (e) => {
    const inputValue = e.target.value; // Get the current value from the textarea
    setMyValue(inputValue); // Update state to reflect user input
    adjustTextareaHeight(); // Adjust height dynamically

    // If autoComplete is enabled, find the first matching suggestion
    if (autoComplete && inputValue) {
      const match = suggestions.find((suggestion) =>
        suggestion.toLowerCase().startsWith(inputValue.toLowerCase())
      );
      setCurrentSuggestion(match ? match.slice(inputValue.length) : ''); // Set the current suggestion
    } else {
      setCurrentSuggestion(''); // Clear suggestion if no match
    }
  };

  // Handle key down events, particularly for the Tab key for autocomplete
  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault(); // Prevent default tab behavior

      if (currentSuggestion) {
        const newValue = myValue + currentSuggestion; // Concatenate the suggestion
        setMyValue(newValue); // Update input with the full suggestion
        adjustTextareaHeight(); // Adjust height after update
        updateMyData(rowID, column, newValue); // Update data
      } else {
        e.target.blur(); // Focus the next input manually if no suggestion
      }
    }
  };

  // Save the data when the textarea is blurred (unfocused)
  const onBlur = () => {
    updateMyData(rowID, column, myValue); 
  };

  return (
    <div className='wide-col'>
      <Row className='d-flex align-items-start p-b-start-02'>
        <Col md={showLinkIcon === true ? 11 : 12}>
          <div className='dynamic-textarea-wrapper'>
            <textarea
              className={`${className ? `${className} dynamic-textarea body-med` : 'dynamic-textarea body-med'}`}
              ref={textareaRef}
              name={column}
              value={myValue}
              onChange={onChange}
              onBlur={onBlur} 
              onKeyDown={handleKeyDown} 
            />
          </div>
        </Col>
        {showLinkIcon === true && (
          <Col md={1} className='align-items-start'>
            <BoxArrowRight onClick={() => handleShowItemModal(rowID)} className='svg-16' />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ReactTblTextAreaEditCell;
import React, { useState, useEffect } from 'react';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import FormattedArticleList from '../News/FormattedArticleList';
import ircUtils from './ircUtils';

function WPNewsWrapper({ cntryISO }) {
    //this component wraps the call to the WP API for news articles and adapts the data for the common news component

    const [newsLoading, setNewsLoading] = useState(true);
    const [newsArticles, setNewsArticles] = useState();

    const formatForNewsList = (rawList) => {
        //comes from WP as
        /*
        {
            "id": 2486,
            "link": "https://www.unicef.org/press-releases/vaccination-campaign-against-cholera-kicks-sudan",
            "title": {
                "rendered": "October 2019: Sudan launches five-day cholera campaign"
            },
            "excerpt": {
                "rendered": "<p>In response to an outbreak declared in September, Sudan launched a five-day cholera campaign on October 11, delivering 1.6 million doses of oral vaccine to people aged one year and above in the [&hellip;]</p>\n",
                "protected": false
            },
            "author": 5,
            "post_region_country": [
                254
            ]
        }
        */

        return rawList.map((thisArticle) => {
            return (
                {
                    fields: {
                        title: thisArticle.title.rendered,
                        body: thisArticle.content.rendered,
                        link: thisArticle.link,
                        datepublished: thisArticle.date
                    }
                })
        });

    };

    const setTheNews = async (iso) => {
        let thenews = await ircUtils.getNewsFromWP(iso);
        setNewsArticles(thenews);
    }

    useEffect(() => {
        setNewsLoading(true);
        setTheNews(cntryISO);
        setNewsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntryISO]);

    return (
        newsLoading ?
            <ListSkeletonLoader preview={false} />
            :
                newsArticles && newsArticles.length > 0 ?
                    <FormattedArticleList
                        list={formatForNewsList(newsArticles)}
                        useStandardNewsLink={false}
                        showTags={false} />
                : null
    )
}

export default WPNewsWrapper
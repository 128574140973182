import React, { useState, useEffect } from 'react';
import { useToast } from '../../components/ToastProvider';
import { Badge, Row } from 'react-bootstrap';


import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import PMTCONSTANTS from '../PMT/pmtConstants';
import utils from '../../utils';
import EditableTextCell from '../PMT/EditableTextCell';

function DemandHealthNestedTable({tenant, groupMembers, appName, apiKey}) {

  const [loading, setLoading] = useState(false);
  const [ data, setData ] = useState();
  const [ activityUpdated, setActivityUpdated ] = useState(false);
  const [ updatedActivity, setUpdatedActivity ] = useState(null);
  
  const { addToast } = useToast();
  
  const getPMTList = async (apiKey, app, inclDel = false) => {

      let url = PMTCONSTANTS[app.fields.apptype].GET_ACTIVITIES;
      const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${url}?include_deleted=${inclDel}`, {
        headers: {
          'Content-Type': 'application/json',
          'access_token': apiKey
        }
      })
      if (result['status'] === 403) {
        alert("Invalid API key, access denied")
      }
  
      let resJson = await result.json()
      await formatNestedData(resJson, app, inclDel);
    }
  
    const handleActivityUpdate = (cellVal) => {
      //receives the rowID, colname, and new value
      let tempData = [...data];
      //put the new value in the col specified and the id of the activity specified in the data array
      //get data state, clone and put the modified item back in the array

      for (let i = 0; i < tempData.length; i++) {
        let activitiesArr = tempData[i].nestedData;

        let activityToUpdate = activitiesArr.filter((activity) => activity.activity_id === cellVal.rowID)
          if(activityToUpdate && activityToUpdate.length > 0) {
            activityToUpdate[0][cellVal.colname] = cellVal.value;
            setUpdatedActivity(activityToUpdate[0])
        }
      }
      setData(tempData)
    
      //store the index of the activity from data to get from the array to submit

      //update the state to trigger a post
      setActivityUpdated(true)
    }

    const postActivityUpdate = async (updateObj) => {
        const result = await fetch(`${process.env.REACT_APP_DB_API_URL}upsert_demand_health_activity?activity_id=${updateObj.activity_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'access_token': apiKey
            //need to add other access token for workspace access here
          },
          body: JSON.stringify(updateObj)
        })
        if (result['status'] === 403) {
          addToast({
            title: 'isValid Key',
            body: "Invalid API key, access denied"})
        }
      setUpdatedActivity(null)
    }
    
    //update the backend with the activity
    useEffect(() => {
      if(activityUpdated) {
        //post the update
        postActivityUpdate(updatedActivity);
        setActivityUpdated(false);
      }
      //eslint-disable-next-line
    }, [ activityUpdated ]);

    useEffect(() => {
      try {
        (async () => {
          
          if(appName && apiKey) {
            setLoading(true)
            await getPMTList(apiKey, appName, false);
            setLoading(false)
          }           
        })()
      } catch (err) {
        addToast({
          title: 'Update Error',
          body: 'Whoops!  something happened.  Please try again.'
        })
      }
      //eslint-disable-next-line 
    }, [appName, apiKey])

    
  
    const getNestedData = async (ISO, app, inclDel) => {
      let url = PMTCONSTANTS[app.fields.apptype].GET_ACTIVITIES_BY_COUNTRY;
      const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${url}?ISO=${ISO}&include_deleted=${inclDel}`, { 

        headers: {
          'Content-Type': 'application/json',
          'access_token': apiKey
        }
      })
      if (result['status'] === 403) {
        alert("Invalid API key, access denied")
      }
  
      let resJson = await result.json();
      return resJson;
    }

  const formatNestedData = async(list, app, inclDel) => {
    list.forEach(async(listItem) => {
      try {
          let listItemData = await getNestedData(listItem.ISO, app, inclDel);
          if (listItemData && listItemData.length > 0) {
            listItem['nestedData'] = listItemData;
          }
      } catch (err) {
        addToast({
          title: 'Update Error',
          body: 'Whoops!  something happened.  Please try again.'
        })
      }
      return listItem
    })
    setData(list);
  };

  const getFormattedDate = (date) => {
    let newDate = date.split('T')
    newDate = newDate[0].split('-');
    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`
  }

  const commentHandler = (e, comments) => {
    let commentsArr = [];
    let newDate = new Date();
    let commentObj = {};
    let userInfo = utils.findUserInfo(tenant.ourUserID, groupMembers)
 
    //Adds comment info to obj
    commentObj.comment_id = comments && comments.length > 0 && comments[0].comment_id ? comments[0].comment_id : null;
    commentObj.activity_id = e.rowID;
    commentObj.owner = userInfo[0].displayName;
    commentObj.created_on = newDate;
    commentObj.comment_value = e.value;
    commentsArr.push(commentObj);
    handleActivityUpdate({rowID: e.rowID, colname: 'comments', value: commentsArr})
}

  return (
    !loading && data ?
    <div className='m-b-end-12 overflow-x-scroll'>
      <h3 className='h3 m-b-start-06'>Demand health by country</h3>
      <p className='label-medium m-b-end-08'>Priority and progress by country across antigens (2023-2025).</p>
      <Row className='p-b-end-06'> 
        <span className='demand-activities-table width-fit-content'>{'1-3 priority (3 is highest)'}</span>
        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY["not started/early stages"]} txt-primary label-small p-i-04 p-b-02 border-radius-lg width-fit-content m-i-start-06`}>Early stages</Badge>
        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY['in progress']} txt-primary label-small p-i-04 p-b-02 border-radius-lg width-fit-content m-i-start-06`}>Planned</Badge>
        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY["planned"]} txt-primary label-small p-i-04 p-b-02 border-radius-lg width-fit-content m-i-start-06`}>In progress</Badge>
        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY["complete"]} txt-primary label-small p-i-04 p-b-02 border-radius-lg width-fit-content m-i-start-06`}>Complete</Badge>
      </Row>
      <table className='full-width m-b-end-08'>
          <thead>
              <tr className='border-bottom-light'>
                <th className='body-small px-width-125 p-i-start-05 surface-primary-background'>Country</th>
                <th className='body-small px-width-100 p-i-start surface-primary-background'></th>
                <th className='body-small px-width-225 p-i-start surface-primary-background'>Financing Status</th>
                <th className='body-small px-width-150 p-i-start surface-primary-background border-right'>2023 Birth Cohort</th>
                <th className='body-small px-width-150 p-i-start-05 surface-primary-background'>Antigen</th>
                <th className='body-small px-width-100 p-i-start surface-primary-background'>{''}</th>
                <th className='body-small px-width-250 p-i-start surface-primary-background'>Details</th>
                <th className='body-small px-width-100 p-i-start-06 surface-primary-background'>Activity Year</th>
                <th className='body-small px-width-225 p-i-start-06 surface-primary-background'>Source</th>
                <th className='body-small px-width-100 p-i-start surface-primary-background'>Update Date</th>
                <th className='body-small px-width-250 p-i-start surface-primary-background'>Comments</th>
              </tr>
          </thead>
          <tbody>
            {
              data.map((row) => {
                
                return (
                  <tr>
                    <td className='px-width-125 body-med vertical-align-top p-i-start-05 p-b-start-03'>{row.country}</td>
                    <td className='px-width-100 body-med vertical-align-top p-b-start-03'>
                      <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY['who region']} txt-primary label-small border-radius-lg p-i-04 p-b-02`}>
                        {row.who_region}
                      </Badge>
                    </td>
                    <td className='px-width-225 body-med vertical-align-top p-b-start-03'>{row.gavi_cofinancing}</td>
                    <td className='px-width-150 body-med vertical-align-top p-b-start-03 border-right'>{row.birth_cohort.toLocaleString()}</td>
                    <td colSpan={7}>
                    {
                      row && row.nestedData ?
                        row.nestedData.map((nestedItem) => {
                          return (
                            <table className='full-width'>
                              <tr>
                                <td className='px-width-150 body-med p-i-start-05 p-b-start-03 vertical-align-top'>{nestedItem.dh_vaccine_market.dh_antigen ? nestedItem.dh_vaccine_market.dh_antigen : ''}</td>
                                <td className='px-width-100 body-med vertical-align-top p-b-start-03'>
                                  {
                                    nestedItem.status ?
                                      <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY[nestedItem.status.toLowerCase()]} txt-primary label-small p-i-04 p-b-02 border-radius-lg`}>{nestedItem.priority ? PMTCONSTANTS[appName.fields.apptype].PRIORITY_KEY[nestedItem.priority.toLowerCase()] : ''}</Badge>
                                    : ''
                                  }
                                </td>
                                <td className='px-width-250'>
                                  <EditableTextCell
                                    className={'m-i-end-06'}
                                    rowId={nestedItem.activity_id} 
                                    colname={'details'}
                                    initialValue={nestedItem.details ? nestedItem.details : ''}
                                    onUpdate={(cellVal) => handleActivityUpdate(cellVal)}
                                  />
                                </td>
                                <td className='px-width px-width-100'>
                                  <EditableTextCell
                                      className='resize-none p-i-start-06'
                                      rowId={nestedItem.activity_id} 
                                      colname={'year'}
                                      initialValue={nestedItem.year ? nestedItem.year : ''}
                                      onUpdate={(cellVal) => handleActivityUpdate(cellVal)}
                                  />
                                </td>
                                <td className='px-width-225 p-i-start-06'>
                                  <EditableTextCell
                                        rowId={nestedItem.activity_id} 
                                        className='px-width-200'
                                        colname={'source'}
                                        initialValue={nestedItem.source ? nestedItem.source :' '}
                                        onUpdate={(cellVal) => handleActivityUpdate(cellVal)}
                                  />
                                  </td>
                                <td className='px-width-100 vertical-align-top'>
                                  <div>{nestedItem.updated_date ? getFormattedDate(nestedItem.updated_date) : ''}</div>
                                </td>
                              <td className='px-width-250'>
                                <EditableTextCell
                                  rowId={nestedItem.activity_id} 
                                  className='px-width-250'
                                  colname={'comments'}
                                  initialValue={nestedItem.comments && nestedItem.comments.length > 0 ? nestedItem.comments[0].comment_value :' '}
                                  onUpdate={(cellVal) => commentHandler(cellVal, nestedItem.comments)}
                                />
                              </td>
                              </tr>
                            </table>
                          )
                        })
                      : ''
                    }
                    </td>
                </tr>
                )
              })
            }
          </tbody>
      </table>
      <Row className='p-b-end-06'> 
        <span className='demand-activities-table width-fit-content'>{'1-3 priority (3 is highest)'}</span>
        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY["not started/early stages"]} txt-primary label-small p-i-04 p-b-02 border-radius-lg width-fit-content m-i-start-06`}>Early stages</Badge>
        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY['in progress']} txt-primary label-small p-i-04 p-b-02 border-radius-lg width-fit-content m-i-start-06`}>Planned</Badge>
        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY["planned"]} txt-primary label-small p-i-04 p-b-02 border-radius-lg width-fit-content m-i-start-06`}>In progress</Badge>
        <Badge className={`${PMTCONSTANTS[appName.fields.apptype].COLOR_KEY["complete"]} txt-primary label-small p-i-04 p-b-02 border-radius-lg width-fit-content m-i-start-06`}>Complete</Badge>
      </Row>
    </div>
    : <ListSkeletonLoader preview={false} />
  )
}

export default DemandHealthNestedTable;
import React, { useEffect, useState} from 'react';
import { Row, Col, Button, Table } from 'react-bootstrap'
import { Clipboard, ClockHistory, Search } from 'react-bootstrap-icons';
import Select from 'react-select';

import '../../../styles/layout/_layout.scss'
import ObjectSelector from "../ObjectSelector";
import EnumSelector from '../EnumSelector'
import AltNames from './AltNames';
import utils from '../../../utils';
import { useToast } from '../../ToastProvider';
import RelatedDataTable from '../RelatedDataTable';
import NumericInput from '../NumericInput';


function ProductEditorBasic({apiKey, product, setProduct, fetchData, organizations, disableProductEdits, showMSTFields, checkProduct, closePopup, toastClick, sendToLocalStorage}) {
  const [showHistory, setShowHistory] = useState(false);
  const [productHistory, setProductHistory] = useState([]);
  /*----------------------State Variables-----------------------------------*/

  const { addToast } = useToast();


  useEffect(() => {
    if (showHistory === false){
      return;
    }
    const fetchData = async () => {
      if (!apiKey || !product.product_id) {
        return
      }
      const response = await utils.getData('get_product_history', apiKey, '?product_id=' + product.product_id)
      if (typeof response !== 'string') {
        const formattedResponse = response.map((r) => {return {...r, change_date:(new Date(r.change_date).toLocaleDateString())}})
        setProductHistory(formattedResponse)
      } else {
        sendToLocalStorage();
        addToast({
          title: 'Failed to load data. Error code 1-e',
          body: response,
          onClick:toastClick,
          buttonText:"Reopen product?"
        });
        closePopup();
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, product.product_d, showHistory])

  function setManufacturer(mfg) {
    if (mfg ===null){
      setProduct({
        ...product,
        manufacturer_id: null,
        manufacturer: null
      })
    } else {
      setProduct({
        ...product,
        manufacturer_id: mfg.org_id,
        manufacturer: mfg
      })
    } 
  }

  function setVaccine(v){
    setProduct({
      ...product,
      vaccine_id:v.vaccine_id,
      vaccine:v
    })
  }


  function setMinAgeUnit(e) {
    const orig = product.min_age_unit
    const updated = e.label
    var multiplier = 1
    if (orig === "Week(s)" && updated === "Year(s)") {
      multiplier = 1 / 52
    } else if (orig === "Week(s)" && updated === "Month(s)") {
      multiplier = 0.230137
    } else if (orig === "Month(s)" && updated === "Week(s)") {
      multiplier = 4.34524
    } else if (orig === "Month(s)" && updated === "Year(s)") {
      multiplier = 1 / 12
    } else if (orig === "Year(s)" && updated === "Week(s)") {
      multiplier = 52
    } else if (orig === "Year(s)" && updated === "Month(s)") {
      multiplier = 12
    }
    setProduct({
      ...product,
      min_age_unit: e.label,
      min_age: product.min_age * multiplier
    })
  }

  function setMaxAgeUnit(e) {
    const orig = product.max_age_unit
    const updated = e.label
    var multiplier = 1
    if (orig === "Week(s)" && updated === "Year(s)") {
      multiplier = 1 / 52
    } else if (orig === "Week(s)" && updated === "Month(s)") {
      multiplier = 0.230137
    } else if (orig === "Month(s)" && updated === "Week(s)") {
      multiplier = 4.34524
    } else if (orig === "Month(s)" && updated === "Year(s)") {
      multiplier = 1 / 12
    } else if (orig === "Year(s)" && updated === "Week(s)") {
      multiplier = 52
    } else if (orig === "Year(s)" && updated === "Month(s)") {
      multiplier = 12
    }
    setProduct({
      ...product,
      max_age_unit: e.label,
      max_age: product.max_age * multiplier
    })
  }

  const setPlatforms = (newPlatforms) => {
    setProduct({
      ...product,
      platforms: newPlatforms.map((plat) => {return {platform:plat.label}})
    })
  }
  
  function addProductProductionLocation() {
    // creates a new production location row at the product level
    const production_location = product.production_location
    production_location.push({
      product_id: null,
      production_location_id: null
    })
    setProduct({
      ...product,
      production_location: production_location
    })
  }

  function setRowProductionLocation(prodLoc, index) {
    const production_location = product?.production_location
    if (prodLoc === null) {
      production_location[index].product_id = null
      production_location[index].production_location_id = null
    } else {
      production_location[index].product_id = product.product_id
      production_location[index].production_location_id = prodLoc.value
    }

    setProduct({
      ...product,
      production_location: production_location
    })
  }

  function deleteRowProductionLocation(index) {
    const production_location = [...product.production_location]
    production_location.splice(index, 1)
    setProduct({
      ...product,
      production_location: production_location
    })
  }


  /*-------------------------------Rendering--------------------------------*/
  // Mix of form an modal? Is this the best code pattern?
  return (
    <div>
     
       <Row className='p-b-start-04'>
        <Col md={12} lg={12} xl={9}>
        <Row>
          <Col md={12} lg={4} xl={3}>
            <Button className='surface-primary' onClick = {()=>navigator.clipboard.writeText(product.product_id)}>Copy Product ID <Clipboard className='icon'/></Button>
          </Col>
          <Col md={12} lg={4} xl={3}>
            <Button className='surface-primary' onClick = {()=>setShowHistory(true)}>View Edit History <ClockHistory className='icon'/></Button>
          </Col>
          <Col md={12} lg={4} xl={3}>
            <Button className='surface-primary' onClick={checkProduct}>Check Product <Search className='icon'/></Button>
          </Col>
        </Row>
        </Col>
       </Row>
      
      <Row>
        <Col>
          <div className='input-text'>Vaccine</div>
          <ObjectSelector
            apiKey = { apiKey }
            onChange={setVaccine}
            type='vaccine'
            obj={product.vaccine}
            onSubmit={fetchData}
            disabled={disableProductEdits}
          />
          {product.vaccine !== null ?
            <div>
              <div className='input-text'><strong>Vaccine: </strong>{product.vaccine.vaccine}</div>
              <div className='input-text'><strong>Subtype: </strong>{product.vaccine.vaccine_subtype}</div>
              <div className='input-text'><strong>Antigens: </strong></div>
              <ul>
                {product.vaccine.antigens.map((a, i) => {
                  return <li key={i}>{a.antigen}</li>
                })}
              </ul>
            </div> : null
          }
          <div className='input-text'>GlobalData Drug ID</div>
          <input
            disabled={disableProductEdits}
            className='form-ctrl'
            value={product.global_data_drug_id}
            onChange={(e)=>{setProduct({
              ...product,
              global_data_drug_id:e.target.value === '' ? null : e.target.value
            })}}
          />
          <div className='input-text'>Drug Description</div>
          <textarea
            rows='1'
            disabled={disableProductEdits}
            className='textarea'
            value={product.drug_description}
            onChange={(e)=>{setProduct({
              ...product,
              drug_description:e.target.value
            })}}
          />
          {
            disableProductEdits?
            null:
            <>
              <div className='input-text'>Internal Note</div>
              <textarea
                className='textarea'
                value={product.internal_note}
                onChange={(e)=>{setProduct({
                  ...product,
                  internal_note:e.target.value
                })}}
              />
            </>
          }
          
          <div className='input-text'>Platform</div>
          <EnumSelector
            disabled={disableProductEdits}
            enum_type="platform"
            value={product.platforms.map((plat)=>{return plat.platform})}
            apiKey={apiKey}
            isMulti={true}
            onChange={setPlatforms} 
          />
          <div className='input-text'>Strains</div>
          <input
            disabled={disableProductEdits}
            className='form-ctrl'
            type='text'
            value={product.strains}
            onChange={(e) => (e) = setProduct({
              ...product,
              strains: e.target.value
            })}
          />
          <div className='input-text'>Development Phase</div>
          <EnumSelector
            disabled={disableProductEdits}
            enum_type="development_phase"
            apiKey={apiKey}
            value={product.development_phase}
            onChange={(e) => setProduct({
              ...product,
              development_phase: e.label,
              development_phase_source: "manual"
            })} />

          <div className='input-text'> BMGF Development Phase</div>
          <EnumSelector
            disabled={disableProductEdits}
            enum_type="development_phase"
            apiKey={apiKey}
            value={product.bmgf_development_phase}
            onChange={(e) => setProduct({
              ...product,
              bmgf_development_phase: e.label
            })}
          />
          <div className='input-text'>Min Age</div>
          <Row>
            <Col>
              <NumericInput
                disabled={disableProductEdits}
                className='form-ctrl'
                type="number"
                value={product.min_age}
                onChange={(value) => setProduct({
                  ...product,
                min_age: value === '' ? null : value
                })}
              />
            </Col>
            <Col>
              <EnumSelector
                disabled={disableProductEdits}
                className="select"
                disableCreate={true}
                isClearable={true}
                apiKey={apiKey}
                enum_type="time_unit"
                value={product.min_age_unit}
                onChange={(e) => setMinAgeUnit(e)} />
            </Col>
          </Row>
          <div className='input-text'>Max Age</div>
          <Row>
            <Col>
            <NumericInput
                disabled={disableProductEdits}
                className='form-ctrl'
                type="number"
                value={product.max_age}
                onChange={(value) => setProduct({
                  ...product,
                max_age: value === '' ? null : value
                })}
              />
            </Col>
            <Col>
              <EnumSelector
                disabled={disableProductEdits}
                className="select"
                disableCreate={true}
                isClearable={true}
                apiKey={apiKey}
                enum_type="time_unit"
                value={product.max_age_unit}
                onChange={(e) => setMaxAgeUnit(e)} />
            </Col>
          </Row>
        </Col>
        <Col>
          <div className='input-text'>Product Name</div>
          <input
            disabled={disableProductEdits}
            className='form-ctrl'
            type="text"
            placeholder="Product Name"
            value={product.product_name !== null ? product.product_name : ''}
            onChange={(e) => (e) = setProduct({ ...product, product_name: e.target.value })}
          ></input>
          <AltNames
            disabled={disableProductEdits}
            product={product}
            setProduct={setProduct}
          />
          <div className="input-text">Manufacturer</div>
          <ObjectSelector
            apiKey = { apiKey }
            data={organizations}
            onChange={setManufacturer}
            isClearable={true}
            type='organization'
            onSubmit={fetchData}
            obj={product.manufacturer}
            disabled={disableProductEdits}
          />
          {product.manufacturer !== null ?
            <div>
              <Row>
                <Col>
                  <div className='input-text'><strong>Manufacturer Country: </strong>{
                    product.manufacturer.country?
                      product.manufacturer.country.country : null
                  }</div>
                  <div className='input-text'><strong>Licensure Experience: </strong>{String(product.manufacturer.licensure_experience)}</div>
                  <div className='input-text'><strong>PQ Experience: </strong>{String(product.manufacturer.pq_experience)}</div>
                  <div className='input-text'><strong>Releasing NRA: </strong>{product.manufacturer.country !== null ?
                    product.manufacturer.country.releasing_nra :
                    null}</div>
                </Col>
                <Col>
                  <Table size="sm">
                    <thead className="table-heading">
                      <tr>
                        <th>Production Locations</th>
                        {
                          disableProductEdits?
                          <th></th>:
                          <th className="clickable-text"
                            onClick={addProductProductionLocation}>
                              add
                          </th>
                        }
                      </tr>
                    </thead>
                    <tbody className='table-body'>
                      {product.production_location?.map((o, i) => {
                        return (
                          <tr key={i}>
                            <td className='full-width'>
                              <Select 
                                options={product.manufacturer.production_locations?.map((c) => {
                                  return {
                                    value: c.production_location_id,
                                    label: c.location_name
                                  }})}
                                isClearable={true}
                                onChange={(e) => {setRowProductionLocation(e, i)}}
                                value={{
                                  value: product.production_location[i].production_location_id,
                                  label: product.manufacturer.production_locations.find(
                                    x => x.production_location_id === product.production_location[i].production_location_id
                                  )?.location_name
                                }}
                              />
                            </td>
                            <td className='right-align'>
                              {disableProductEdits? null:
                                <Button 
                                    className='delete-btn'
                                    size="sm"
                                    variant="danger" 
                                    onClick = {() => {deleteRowProductionLocation(i)}}>
                                    -
                                </Button>
                              }
                            </td>
                          </tr>
                        )})}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div> : null
          }
          <div className='input-text'>Product Status</div>
          <EnumSelector
            disabled={disableProductEdits}
            enum_type="lb_status"
            apiKey={apiKey}
            disableCreate={true}
            value={product.lb_status}
            onChange={(e) => setProduct({ ...product, lb_status: e.label })} />

          <div className='input-text'>Form</div>
          <EnumSelector
            disabled={disableProductEdits}
            apiKey={apiKey}
            enum_type="form"
            value={product.form !== null ? product.form : ''}
            onChange={(e) => setProduct({ ...product, form: e.label })} />
  
          <div className='input-text'>Route of Administration</div>
          <EnumSelector
            disabled={disableProductEdits}
            apiKey={apiKey}
            enum_type="route_of_administration"
            value={product.route_of_administration}
            onChange={(e) => setProduct({ ...product, route_of_administration: e.label })} />
          
          <Row>
            <Col>
              <div className='input-text'>Doses Per Regimen</div>
              <NumericInput
                disabled={disableProductEdits}
                className='form-ctrl'
                placeholder='Enter Min...'
                value={product.min_doses_per_regimen !== null ? product.min_doses_per_regimen : ''}
                onChange={(value) => setProduct({
                  ...product,
                  min_doses_per_regimen: value === '' ? null : value
                })}
              />   
            </Col>
            <Col>
              <div className='input-text'>Doses Per Regimen</div>
              <NumericInput
                disabled={disableProductEdits}
                className='form-ctrl'
                placeholder='Enter Max...'
                value={product.min_doses_per_regimen !== null ? product.min_doses_per_regimen : ''}
                onChange={(value) => setProduct({
                  ...product,
                  max_doses_per_regimen: value === '' ? null : value
                })}
              />
            </Col>
          </Row>
          {showMSTFields?
            <>
              <div className='input-text'><strong>Innovation Prioritization</strong></div>
              <div className='input-text'>Level of Benefit</div>
              <EnumSelector
                apiKey={apiKey}
                enum_type="level_of_benefit"
                value={product.level_of_benefit}
                isClearable = {true}
                onChange={(e) => setProduct({ ...product, level_of_benefit: e.label })}
              />
              <div className='input-text'>Support Required</div>
              <EnumSelector
                apiKey={apiKey}
                enum_type="support_required"
                value={product.support_required}
                isClearable = {true}
                onChange={(e) => setProduct({ ...product, support_required: e.label })}
              />
              <div className='input-text'>Prioritization Category</div>
              <EnumSelector
                apiKey={apiKey}
                enum_type="prioritization_category"
                value={product.prioritization_category}
                isClearable = {true}
                onChange={(e) => setProduct({ ...product, prioritization_category: e.label })} />
            </>

          :null}
          
          </Col>
      </Row>
      {showHistory?
        <RelatedDataTable
          data={productHistory}
          closePopup={()=>setShowHistory(false)}
          title='Product History'
        />
        :null
      }
    </div>
  );
}


export default ProductEditorBasic;

import React from 'react';
import { Row, Col } from 'react-bootstrap';

import TagDisplay from '../TagDisplay';

import utils from '../../utils';

function ArticleItem({ workspace, article}) {

    return (
        <>
            <Col>
                <h1 className='article-display h1 m-b-end-08'>{article.title}</h1>
            </Col>
            <Row className='article-body'>
                <p className='subtitle-lg secondary-link m-b-end-04'>
                    {utils.getDateString(article.datepublished, 'long')}
                    <span className='subtitle-lg secondary-link m-i-start-04'>{article.author}</span>
                </p>
                <Col className="body-col">
                    <p className='body-text' dangerouslySetInnerHTML={utils.getHTML(article.body)} />
                    {article.links ?
                        <p className="secondary-link-plain" dangerouslySetInnerHTML={utils.getHTML(utils.addTargetToLinksInHtml(utils.removeURLPlaceholder(article.links, 'news')))} />
                        : null}
                    {article.tags ?
                        <TagDisplay
                            tagList={article.tags}
                            flatList={false}
                        />
                        : null}
                </Col>
            </Row>
            

        </>
    )
}

export default ArticleItem
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CountryMap from './CountryMap';

import utils from '../../utils';

function CountryProfilePanel({ countryInfo, contentMaster }) {

    const specFormat = {
        last_known_gni: "number",
        total_population : "number"
    }

    return (
        <>
            <Row>
                <Col>
                    <div dangerouslySetInnerHTML={{ __html: utils.formatForInfo(contentMaster.fields.countryProfileLayout, countryInfo, specFormat) }} />
                </Col>
                <Col>
                    <CountryMap 
                        country={countryInfo} />
                </Col>
            </Row>
        </>
        
    )
}

export default CountryProfilePanel
import React, { useState } from 'react';
import CONSTANTS from '../constants';
import IntellMockNewReport from './Intelligence/IntellMockNewReport';
import BreadCrumbBar from './Layout/Nav/BreadCrumbBar';
import UniversalElasticConnectedList from './UniversalElasticConnectedList';

function ElasticAppHarness({ tenantSetting, appType, headerText, pageType, preview, pollingInterval=null, metaData, searchTerm=null, draftArticles=null}) {
    //this app works to wrap elastic app search, providing filters, breadcrumbs, app name, etc.
    // three apps currently using elastic - Intelligence List Page, News List Page, and Search Results Page

    const [newReport, setNewReport] = useState();

    const checkIfSessionArticleExists = (sessReport, artList) => {
        let repExists = artList.some(art => art.contentID.toString() === sessReport.id.toString())

        if(!repExists) {
            setNewReport(sessReport);
        }
        sessionStorage.clear();
    }

    const checkLocalForArticles = (articles) => {
        let newStorageReport = sessionStorage.getItem("newReport");

        if(newStorageReport && JSON.parse(newStorageReport).id ) {
            let report = JSON.parse(newStorageReport);
            checkIfSessionArticleExists(report, articles)
        }
    }

    return (

        <>
            { !preview ?
                <>
                    <BreadCrumbBar appName={appType} />
                    <h1 className='h1 m-b-end-08'>{ headerText ? headerText : null }</h1>
                </>
            : null }

            {
                newReport ?
                    <IntellMockNewReport article={newReport} />
                :
                ''
            }
            <UniversalElasticConnectedList
                pageType={pageType}
                engineName={CONSTANTS.ELASTIC_ENGINES_BY_APP[appType.toLowerCase()]}
                searchTerm={searchTerm}
                pollingInterval={preview ? null: pollingInterval}
                page={0}
                metaData={metaData}
                numPerPage={preview === true ? 4 : 10}
                tenantSetting={tenantSetting}
                preview={preview}
                onTotalPagesReceived={()=> {}} 
                onArticlesRetrieved={(art) => {checkLocalForArticles(art)}}/>        
        </>                     
    )
}

export default ElasticAppHarness
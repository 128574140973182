import React from 'react';
import { Search } from 'react-bootstrap-icons'

function DemandHealthToolbar({searchTermHandler}) {
    
  return (
    <div className='m-b-end-03 align-right'>
        <Search className='txt-primary'/>
        <input
            autoFocus
            className="antigen-search mx-3 my-2 w-auto border-none m-i-start-02"
            placeholder="Search by..."
            onChange={(e) => searchTermHandler(e.target.value)}
        />
    </div>
  )
}

export default DemandHealthToolbar
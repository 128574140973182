
import {useState, useEffect} from 'react'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { useToast } from '../ToastProvider';
import utils from "../../utils"
import '../../styles/layout/_layout.scss'

function EnumSelector({enum_type, onChange, apiKey, disabled=false, value=null, isMulti=false, 
    clearAfterSelect=false, disableCreate=false, className=null, doAPICall=null, enumData=null, isClearable=false}) {

  /*--------------------------State variables---------------------------*/
  const [allEnums, setAllEnums] = useState([])
  const [enumOptions, setEnumOptions] = useState([])
  const [deletedEnums, setDeletedEnums] = useState([])
  const [enumSelection, setEnumSelection] = useState(null)
  const [error, setError] = useState([]);

  const { addToast } = useToast();

  useEffect(() => {
    if(!value || allEnums.length===0){
      setEnumSelection(null);
      return
    }
    if (isMulti){
      setEnumSelection(
          allEnums.filter((e) => value.includes(e.label))
        )
    } else {
      const enums =  allEnums.filter((e) => value === e.label)
      if (enums.length > 0){
        setEnumSelection(enums[0])
      }
      
    }
    // React 18 ready
    return () => { };
  }, [value, isMulti, allEnums])

  useEffect(()=>{
    if (!enumSelection){
      return;
    }
    setError('')
    const tempErrors = []
    if(isMulti){
      enumSelection.forEach((elt)=>{
        if (elt.value.deleted===true){
          tempErrors.push(elt.value.enum)
        }
      })

    } else{
      if (enumSelection.value.deleted===true){
        tempErrors.push(enumSelection.value.enum)
      }
    }
    
    if (tempErrors.length === 1){
      setError('This option has been deleted. Please consider replacing it.')
    } else if (tempErrors.length === 2){
      setError('The options '+ tempErrors[0] + ' and ' + tempErrors[1] +' have been deleted. Please consider replacing them.')
    } else if (tempErrors.length >2){
      const last = tempErrors.pop();
      const mainErrors = tempErrors.join(', ')
      setError('The options '+ mainErrors + ', and ' + last +' have been deleted. Please consider replacing them.')
    }
    // React 18 ready
    return () => { };
    // eslint-disable-next-line
  }, [enumSelection, isMulti])


  

/*-------------------------Get the requested enums-------------------*/
  useEffect(() => {
    async function fetchData() {
      // If enumData is available, use it instead of making an API call
      if (enumData && doAPICall === true) {
        setEnumOptions(enumData);
        setAllEnums(enumData);
  
      } else {
        // Fetch the enum data from the API if no enumData is passed
        let enumResult = await utils.getData('get_enums', apiKey, `?enum_type=${enum_type}&include_deleted=True`);
        if (typeof enumResult === 'string') {
          addToast({
            title: 'Failed to load enum data. Error code 2-a',
            body: enumResult,
          });
          return;
        }

        const temp = enumResult.map(en=>{return {label:en.enum, value: en}})

        // Sorting is pushed to the backend now.
        setEnumOptions(temp.filter((en) => en.value.deleted === false));
        setAllEnums(temp);
        setDeletedEnums(enumResult.filter((en) => en.deleted === true));
      }
    }
    fetchData();
    // React 18 ready
    return () => { };
   // eslint-disable-next-line
  }, [enum_type, apiKey, addToast, enumData, doAPICall]);



  const insertEnumIfNew = async (enumSelection) => {
    // Inserts the enum into the database if user agrees and it passes the checks
    if (enumSelection.__isNew__){
      var deletedEnum = deletedEnums.filter(en => en.enum.toLowerCase() === enumSelection.value.toLowerCase())
      if (deletedEnum.length > 0){
        deletedEnum = deletedEnum[0]
        if (deletedEnum.replacement === null){
          alert("This enum has been deleted, please try another")
        } else {
          alert("This enum has been deleted, consider replacing with " + deletedEnum.replacement.enum)
        }
        
        return
      }
      const confirmation = window.confirm("Are you sure you want to add "+enumSelection.value+" to the database?")
      if (confirmation === false) {
        return
      }
      const data = {enum_type: enum_type, enum: enumSelection.value, deleted:false}
      const result = await utils.upsert('create_enum', apiKey, data)
      if (typeof result === 'string') {
        window.alert("An error occured, please contact an administrator")
      }else{
        enumOptions.push({label:enumSelection.label, value:enumSelection.value})
      }
    }
  }

  /*-------------------------Select the enum----------------------------------*/
  async function handleChange(e) {
    if (!e){
      setEnumSelection(null)
      onChange({value:null})
      return
    }
    
    if(isMulti){
      for (let enumSelection of e){
        insertEnumIfNew(enumSelection)
      }
    } else {
      insertEnumIfNew(e)
    }
    
    if (clearAfterSelect !== true){
      setEnumSelection(e)
    }
    onChange(e)
  }

  return (
    <div>
      {disableCreate? 
        <Select
          className = {className?className: "select-lg"}
          isClearable = {isClearable}
          options = { enumOptions }
          isDisabled = { disabled}
          onChange = {(e) => handleChange(e)}
          value = { enumSelection }
          isMulti = {isMulti}
        />:
        <CreatableSelect
          className = {className?className: "select-lg"}
          isClearable = {isClearable}
          options = { enumOptions }
          onChange = {(e) => handleChange(e)}
          value = { enumSelection }
          isDisabled={ disabled}
          isMulti = {isMulti}
        />
      }
      {error}
      
    </div>
  )
}

export default EnumSelector;
import React from 'react';
import { useHistory } from 'react-router-dom';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';
import WPEngineList from '../../components/CENews/WPEngineList';


function CENewsAppPage({ tenant }) {

    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.CE_NEWS);

    if(!hasAccess) {
        history.push('/');
    }

    return (
        <div className="full-news-app">
          <WPEngineList tenantSetting={ tenant } preview={ false } />
        </div>
    )
}

export default CENewsAppPage

import React, { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ListPagination from '../Layout/ListPagination';
import utils from '../../utils';
import FilterBarAgility from '../Layout/Filters/FilterBarAgility';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';


function FilteredPagedCoProfList ({ rawList, tenant }) {
    
    //initially, set filteredList to rawList
    let [ filteredList, setFilteredList ] = useState(rawList);
    let [ curPage, setCurPage ] = useState(1);

    // Adds 10 companies per page of pagination and calculates pages
    const NUMBER_PER_PAGE = 10;
    const lastArticleIndex = curPage * NUMBER_PER_PAGE;
    const firstArticleIndex = lastArticleIndex - NUMBER_PER_PAGE;
    const currentArticles = filteredList ? filteredList.slice(firstArticleIndex, lastArticleIndex) : ''
   

    const metaData = [
        //TITLE: What should display on UI
        //DISPLAYFIELD: What is stored in CONSTANTS
        //FILTERFIELD: JSON field to filter the master list on
        {title:'Country', displayField:'country_filter', filterField: 'headquartersCountry'},
        {title:'Search by Name', displayField:'name_filter', filterField: 'name'},
        {title:'Company Type', displayField:'company_type_filter', filterField: 'companyType'},
    ]


    //Gets breadcrumb dynamically based on if an app alternate title exists
    const getBreadCrumbName = (tenInfo) => {
        let breadCrumb;

        tenInfo.tenant.fields.apps.forEach((app) => {
            if (app.fields.apptype === "company_profiles") {
                if (app.fields.appAlternateTitle) {
                    breadCrumb = 'my_company_profile';
                } else {
                    breadCrumb = 'company_profiles';
                }
            }
        })
        return breadCrumb;
    }

    //renders appropriate data based on filters applied
    const changeFilteredList = (newList) => {
        //clears filters and set to original list
        if (newList === 'clear-filters' ) {
            setFilteredList(rawList);
        } else {
            // filters original list to show applied filters
            for ( let i=0; i<newList.length; i++ ) {
                filteredList.filter(item => item.contentId === newList.contentId)
                
            }
            setFilteredList(newList);
        }
    }

    const paginate = (num) => {
        setCurPage(num);
    }

    //company preview - logo, name, introduction, slugname for link
    //filters - sort by (most recent, date, ...?), Country & search filter (this may be 1.2)
    return (
        <>
            <BreadCrumbBar 
                appName={getBreadCrumbName(tenant)}  
            />
            <h1 className='m-b-end-08 h1'>Company Profiles</h1>
            <Row>
                <Col lg={9}>
                    <div className="list-container">
                        {
                            filteredList && filteredList.length > 0 ?
                                <>
                                    {
                                        currentArticles.map((thisItem, index) => {

                                            return (
                                                <>
                                                    <LinkContainer to={`/company_profiles/${thisItem.fields.slugname}/`} className='link-container'>
                                                        <Row key={index} className="app-list-item">
                                                            {
                                                                thisItem.fields.logo ?
                                                                    <Image className="company-logo" src={thisItem.fields.logo.url} alt={thisItem.fields.name} />
                                                                    : null
                                                            }
                                                            <Row>
                                                                <h3>{thisItem.fields.name}</h3>
                                                                <p>
                                                                    {
                                                                        utils.getPreviewTextFromHTML(thisItem.fields.introduction, 250)
                                                                    }
                                                                </p>
                                                            </Row>
                                                        </Row>
                                                    </LinkContainer>
                                                </>
                                            )
                                        })
                                    }

                                    {
                                        filteredList.length > NUMBER_PER_PAGE ?
                                            <Row className="pagination-row m-b-end-04">
                                                <ListPagination
                                                    articlesPerPage={NUMBER_PER_PAGE}
                                                    totalArticles={filteredList.length}
                                                    paginate={paginate}
                                                    curPage={curPage}
                                                />
                                            </Row>
                                        : ''
                                    }
                                </>
                                : <div className="text-center">No Results with the Current Filter</div>
                        }
                    </div>
                </Col>
                <Col lg={3} className='uni-filter-bar'>
                    <FilterBarAgility
                        //metaData refers to filter to display based on app
                        metaData={metaData}
                        data={rawList}
                        myFilterListChanged={changeFilteredList}
                        apiName='agility'
                    />
                </Col>
            </Row>

        </>
    )
}


export default FilteredPagedCoProfList

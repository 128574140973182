import React, {useState} from 'react';
//dependency imports
import { Image, Tabs, Tab } from 'react-bootstrap';

//file imports
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import marketActionsPreviewImage from '../../assets/market-actions-cover.png';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import MarketActionsSummaryView from '../PMT/PMTChartDashboard/MarketActionsSummaryView';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import DemandHealthDisplay from './DemandHealthDisplay';
import PMTCONSTANTS from '../PMT/pmtConstants';
import pmtutils from '../PMT/pmtutils';
import DemandHealthNestedTable from './DemandHealthNestedTable';


function DemandHealthApp({ objectivesList, tenantSetting, appTitle=null,  preview=false, apiKey, appName=null,updateListItemForItemChange, loading=false, memberList }) {
    let breadCrumbName = utils.findURLPath(tenantSetting.tenant.fields.apps, appTitle)
    const [key, setKey] = useState();

    return (
        <div className="market-actions-display">
            {
                !preview ?
                    <>
                        <BreadCrumbBar
                            appName={breadCrumbName}
                        />
                        <h1 className='m-b-end-08 h1'>{CONSTANTS.APPS_BY_NAME[breadCrumbName.toUpperCase()]}</h1>
                        <Tabs
                            activeKey={key}
                            id='pmt-user-tabs'
                            className='app-tabs m-b-end-08'
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab 
                                eventKey={'cross-cutting'}
                                title='Cross-Cutting Profiles'
                            >
                                {
                                    !loading  && tenantSetting ? 
                                        <MarketActionsSummaryView 
                                            appName={appName.fields.apptype}
                                            reportID={PMTCONSTANTS[appName.fields.apptype].DASH_PBI_CROSS_CUTTING.reportID}
                                            datasetID={PMTCONSTANTS[appName.fields.apptype].DASH_PBI_CROSS_CUTTING.datasetID}
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'country-profiles'}
                                title='Country Profiles'
                            >
                                {
                                    !loading  && tenantSetting ? 
                                        <MarketActionsSummaryView 
                                            appName={appName.fields.apptype}
                                            reportID={PMTCONSTANTS[appName.fields.apptype].DASH_PBI_COUNTRIES.reportID}
                                            datasetID={PMTCONSTANTS[appName.fields.apptype].DASH_PBI_COUNTRIES.datasetID}
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'antigen-profiles'}
                                title='Antigen Profiles'
                            >
                                {
                                    !loading  && tenantSetting ? 
                                        <MarketActionsSummaryView 
                                            appName={appName.fields.apptype}
                                            reportID={PMTCONSTANTS[appName.fields.apptype].DASH_PBI_ANTIGEN.reportID}
                                            datasetID={PMTCONSTANTS[appName.fields.apptype].DASH_PBI_ANTIGEN.datasetID}
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'objective'}
                                title='Objectives'
                            >
                                {
                                    objectivesList && objectivesList.length > 0 ?
                                        <DemandHealthDisplay
                                            tenant={tenantSetting}
                                            memberList={memberList}
                                            apiKey={apiKey}
                                            appName={appName}
                                            tableHeaderConfig={pmtutils.getTableconfig('OBJECTIVES', appName, objectivesList, updateListItemForItemChange, apiKey)}
                                            topList={objectivesList}
                                            loading={loading}
                                            configType={'OBJECTIVES'}
                                            handleListUpdate={updateListItemForItemChange}
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'country-activities'}
                                title='Country Activities'
                            >
                                <DemandHealthNestedTable
                                    tenant={tenantSetting}
                                    groupMembers={memberList}
                                    apiKey={apiKey}
                                    appName={appName}
                                    tabKey={key}
                                />
                            </Tab>
                        </Tabs>
                    </>
                    :
                    <div className='preview-img-div'>
                        <Image className='preview-card-img' src={appName && appName.fields && appName.fields.previewImage && appName.fields.previewImage.url ? appName.fields.previewImage.url : marketActionsPreviewImage} />
                    </div>
            }
        </div>
    )
}

export default DemandHealthApp
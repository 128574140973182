import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import ArticleItem from '../News/ArticleItem';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';

import utils from '../../utils';

function CEArticleDisplay({ workspace }) {

  let { articleID } = useParams();

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState();

  //hubspot analytics
  useEffect( () => {
    utils.initializeTracking();
  });

  const articleItemAdapter = (rawArticle) => {
    return {
      title: rawArticle.title.rendered,
      body: rawArticle.content.rendered,
      datepublished: rawArticle.date,
      author: 'CE Team'
    }
  }

  //Gets single article and saves in state
  const getArticle = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_CE_WP_URL}posts/${articleID}`, {
      });
      const data = await res.json();
      setArticle(articleItemAdapter(data));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    getArticle();
    setLoading(false)
    // eslint-disable-next-line
  }, []);

  //convert to article that can work with the ArticleDisplay component

  return (
      <div className='article-display'>
        {
          !loading && article ?
          <>
                <Col>
                    <BreadCrumbBar 
                        appName="CE_NEWS" 
                        item={article} 
                        displayFieldName="title" />
                </Col>
                <ArticleItem
                    workspace={workspace}
                    article={article} 
                    showRelatedFields={true} />
            </>
            :
            <ListSkeletonLoader preview={false} />
        }
      </div>
  )
}

export default CEArticleDisplay;
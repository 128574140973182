import React, {useEffect} from 'react';
import { Button } from 'react-bootstrap'
import NumericInput from '../NumericInput';


function PriceDetailEditor(props) {


  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === 'number' && document.activeElement === event.target) {
        event.preventDefault();
      }
    };

    // Attach event listener
    document.addEventListener('wheel', handleWheel, { passive: false });

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);



  return (
    <tr>
      <td>
        {props.price_detail.temple_id}
      </td>
      <td>
        <input
          className='form-ctrl'
          value={props.price_detail.year}
          type="number"
          onChange={(e)=>props.onChange({...props.price_detail, year:e.target.value})}
        />
      </td>
      <td>
        <input
          className='form-ctrl'
          value={props.price_detail.publish_date}
          type='date'
          onChange={(e)=>props.onChange({...props.price_detail, publish_date:e.target.value})}
        />
      </td>
      <td>
        <NumericInput
          className="form-ctrl"
          placeholder=""
          value={props.price_detail.price_low}
          onChange={(e)=> props.onChange({...props.price_detail, price_low: e ? e : null})}
          allowDecimals={true}
          allowCommas={true}
        />
      </td>
      <td>
         <NumericInput
          className="form-ctrl"
          placeholder=""
          value={props.price_detail.price_high}
          onChange={(e)=> props.onChange({...props.price_detail, price_high: e ? e : null})}
          allowDecimals={true}
          allowCommas={true}
        />
      </td>
      <td>
        <textarea
          className='full-width'
          value={props.price_detail.comments}
          onChange={(e)=>props.onChange({...props.price_detail, comments:e.target.value})}
        />
      </td>
      
      <td>
        <textarea
          className='full-width'
          value={props.price_detail.source_url}
          onChange={(e)=>props.onChange({...props.price_detail, source_url:e.target.value})}
        />
      </td>
      
      <td className='right-align'>
        <Button className='btn-primary' variant='danger' onClick={props.onDelete} size='sm'>-</Button>
      </td>
    </tr>
  )
}

export default PriceDetailEditor;
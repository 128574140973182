import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Form } from 'react-bootstrap'
import _ from "lodash"

import ObjectSelector from '../ObjectSelector'
import EnumSelector from '../EnumSelector';
import UptakeCell from './UptakeCell';
import '../../../styles/layout/_layout.scss';

function ProductSelectionEditor(props) {
  const [yearOptions, setYearOptions] = useState([]) 
  const [monthOptions, setMonthOptions] = useState([])
  const [startYearSelection, setStartYearSelection] = useState(null)
  const [startMonthSelection, setStartMonthSelection] = useState(null)
  const [endYearSelection, setEndYearSelection] = useState(null)
  const [endMonthSelection, setEndMonthSelection] = useState(null)

  // Disable mouse scroll in numeric fields
  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === 'number' && document.activeElement === event.target) {
        event.preventDefault();
      }
    };

    // Attach event listener
    document.addEventListener('wheel', handleWheel, { passive: false });

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  
  useEffect(()=>{
    // All of these really only need to be set once. After that the normal state management
    // will keep them up to date. Added to improve speed of updates.
    function instantiate(){
      // Store local copy of month options and year options to allow concurency to work
      const yo = _.range(1950,2041,1).map((y) => {return {label:y, value:y}})
      const mo = [
        {label:'Jan', value: 1},{label:'Feb', value: 2},{label:'Mar', value: 3},
        {label:'Apr', value: 4},{label:'May', value: 5},{label:'Jun', value: 6},
        {label:'Jul', value: 7},{label:'Aug', value: 8},{label:'Sep', value: 9},
        {label:'Oct', value: 10},{label:'Nov', value: 11},{label:'Dec', value: 12},
      ]
      // Now let react do it's magic in setting this the year options and all the 
      // selections
      setYearOptions(yo)
      setMonthOptions(mo)
      setStartYearSelection(
        props.productSelection !== null?
        {label:props.productSelection.start_year, value:props.productSelection.start_year}:
        null)
      setStartMonthSelection(
        props.productSelection !== null?
        mo.filter((m) => m.value === props.productSelection.start_month)[0]:
        null)
      setEndYearSelection(
        props.productSelection !== null?
        {label:props.productSelection.end_year, value:props.productSelection.end_year}:
        null
      )
      setEndMonthSelection(props.productSelection !== null?
        mo.filter((m) => m.value === props.productSelection.end_month)[0]:
        null)

    }
    instantiate()
    // eslint-disable-next-line
  },[])


  function uptakeOverrideChange(e, y){
    // Get rid of the old value
    var temp = props.productSelection.uptake_overrides.filter((ur)=> ur.year!==y)
    if (e.target.value !== ''){
      // Add in the new value if the event isn't null
      temp = temp.concat({year:y, uptake_or_trs:e.target.value, 
        product_selection_id:props.productSelection.product_selection_id});
    }
    props.onChange({
      ...props.productSelection,
      uptake_overrides:temp
    })
  }

  function getUptakeValue(y){
    // this will be an array of at most 1 element--the year requested
    const filteredSelections = props.productSelection.uptake_overrides.filter((ur)=> ur.year===y)
    if (filteredSelections.length >0){
      // Get the value from the array
      return filteredSelections[0].uptake_or_trs
    } else{
      // Explicit null return, not really necessary but here for clarity
      return null
    }
  }

  function showCharacteristics(pc){
    if (pc=== null || pc === undefined){
      return null
    }
    return <div>
      <div>{pc.product_name}</div>
      <div>{pc.form}</div>
      <div>{pc.manufacturer}</div>
      <div>{pc.vaccine_subtype}</div>
      <div>{pc.container_quantity} dose {pc.container_type}</div>
    </div>
  }

  function handleCharacteristicChange(e){
    var wastage = props.wastages.filter((w)=> 
        w.container_quantity ===e.container_quantity && w.routine===props.routine)
    if (wastage.length > 0) {
      wastage = wastage[0]
    } else{
      wastage = null
    }
    props.onChange({...props.productSelection, 
      product_characteristic_id:e.product_characteristic_id,
      product_characteristic: e,
      wastage:wastage,
      wastage_id:wastage===null?null:wastage.wastage_id,
      updateDemand:true
    })
  }

  return (
     <tr>
       <td>
        <div>
          <Button variant="danger" size='sm' onClick = {props.deleteSelection}>-</Button>
        </div>
        <div>
          {
            props.doseSequence > 1?
            <Button variant='light' size='sm' onClick = {()=>props.onMoveUp()}>↑</Button>
            :null
          }
          {
            props.doseSequence < props.noSchedRounds?
            <Button variant='light' size='sm' onClick = {()=>props.onMoveDown()}>↓</Button>
            :null
          }
        </div>
       </td>
       <td>
        <input
        className='form-ctrl'
        type="number"
        isValid={false}
        value={props.productSelection.min_age}
        onChange={(e)=>{
          props.onChange({...props.productSelection, min_age:e.target.value, updateDemand:true})
        }}
        />
        <EnumSelector
          enum_type='time_unit'
          value={props.productSelection.min_age_unit}
          apiKey={props.apiKey}
          disableCreate={true}
          className='select-md'
          onChange={(e)=>{
            props.onChange({...props.productSelection, min_age_unit:e.label, updateDemand:true})
          }}
        />
      </td>
      <td>
        <input
        className='form-ctrl'
        type="number"
        value={props.productSelection.max_age}
        onChange={(e)=>{
          props.onChange({...props.productSelection, max_age:e.target.value, updateDemand:true})
        }}
        />
        <EnumSelector
          enum_type='time_unit'
          value={props.productSelection.max_age_unit}
          apiKey={props.apiKey}
          disableCreate={true}
          className='select-md'
          onChange={(e)=>{
            props.onChange({...props.productSelection, max_age_unit:e.label, updateDemand:true})
            
          }}
        />
      </td>
      <td>
        <ObjectSelector
          data = {props.productCharacteristics}
          obj = {props.productSelection.product_characteristic}
          type = 'product_characteristic'
          apiKey={props.apiKey}
          onChange={handleCharacteristicChange}
          fetchData={false}
          className='select-lg'
        />
        {showCharacteristics(props.productSelection.product_characteristic)}
      </td>
      <td>
        <input
          className='form-ctrl'
          placeholder={props.productSelection.wastage===null?null:props.productSelection.wastage.wastage}
          value = {props.productSelection.wastage_override}
          onChange ={(e) => props.onChange({
            ...props.productSelection,
            wastage_override:e.target.value===''?null:e.target.value,
            updateDemand:true
          })}
        />
      </td>
      <td>
        <input
          className='form-ctrl'
          value={props.productSelection.multiplier}
          onChange={(e)=>props.onChange({
            ...props.productSelection,
            multiplier:e.target.value ===''? null:e.target.value,
            updateDemand:true
          })}
        />
      </td>
      <td>
        <Select 
          className='select-sm'
          options={monthOptions}
          value={startMonthSelection}
          onChange={
            (e) => {
              props.onChange({
                ...props.productSelection,
                start_month:e.value,
                updateDemand:true
              })
              setStartMonthSelection(e)
            }
          }
        />
        <Select 
          className='select-sm'
          options={yearOptions}
          value={startYearSelection}
          onChange={
            (e) => {
              props.onChange({
                ...props.productSelection,
                start_year:e.value,
                updateDemand:true
              })
              setStartYearSelection(e)
            }
          }
        />
      </td>
      <td>
        <Select 
          className='select-sm'
          options={monthOptions}
          value={endMonthSelection}
          onChange={
            (e) => {
              props.onChange({
                ...props.productSelection,
                end_month:e.value,
                updateDemand:true
              })
              setEndMonthSelection(e)
            }
          }
        />
        <Select 
          className='select-sm'
          options={yearOptions}
          value={endYearSelection}
          onChange={
            (e) => {
              props.onChange({
                ...props.productSelection,
                end_year:e.value,
                updateDemand:true
              })
              setEndYearSelection(e)
            }
          }
        />
      </td>
      {!props.routine && (
        <td>
          <EnumSelector
            enum_type='activity_type'
            value={props.productSelection.activity_type}
            apiKey={props.apiKey}
            disableCreate={true}
            className='select-md'
            onChange={(e) => {
              props.onChange({ ...props.productSelection, activity_type: e.label, updateDemand: false });
            }}
          />
        </td>
      )}


      <td>
        <Form.Check
          onChange={() => {
            props.onChange({
                ...props.productSelection,
                is_intel:!props.productSelection.is_intel,
                updateDemand:false
              })
            }
          }
          checked = {props.productSelection.is_intel}
        />
      </td>
      <td>
        <textarea
            onChange={(e) => {
              props.onChange({ ...props.productSelection, 
                  internal_notes: e.target.value,
                  updateDemand:false
                })
            }}
            value={props.productSelection.internal_notes}
          />
      </td>
      <td>
        <textarea
          onChange={(e) => {
            props.onChange({ 
                ...props.productSelection, 
                external_notes: e.target.value,
                updateDemand:false
              })
          }}
          value={props.productSelection.external_notes}
        />
      </td>
      <td>
        <textarea
            onChange={(e) => {
              props.onChange({ ...props.productSelection, 
                  schedule: e.target.value,
                  updateDemand:false
                })
            }}
            value={props.productSelection.schedule}
          />
      </td>
      {
        props.doseYears !== null?
          props.doseYears.map((y) =>{return (
          <td>
            {props.defaultUptake && props.defaultUptake[y] !== undefined? 
              <UptakeCell
                default={props.defaultUptake[y]}
                key={y}
                value = {getUptakeValue(y)}
                onChange={(e)=> uptakeOverrideChange(e, y)}
              />
              :null
            }
          </td>)})
        :null
      }
    </tr>
  )
}

export default ProductSelectionEditor;